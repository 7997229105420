import React, { useEffect, useState } from 'react';
import { Select, Skeleton } from 'antd';
import Call from '../../../config/Call';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';

const { Option } = Select;

const TiendasSelect = ({ user_cargo, tiendas_user, all_tiendas }) => {
    const [cod_tienda, setCodTienda] = useState('');
    const [tiendas, setTiendas] = useState([]);
    const [promedios, setPromedios] = useState([]);
    const [loading, setLoading] = useState(false);
    const [urlDowload, setUrlDownload] = useState('');

    const COD_CARGO_GERENTE_ZONA = '25';
    const COD_CENTRO_COSTOS_COORDINADOR = '95'

    useEffect(() => {
        if (all_tiendas && all_tiendas.length > 0) {
            setTiendas(all_tiendas);
            setUrlDownload('all_tiendas')
            return
        }
        setTiendas(tiendas_user)
        setUrlDownload('gerente_zona_tiendas')
    }, [all_tiendas]);

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 2,
        }).format(value);
    };

    const handleChange = async (value) => {
        setLoading(true);
        setCodTienda(value);

        if (isNaN(value)) {
            Swal.fire({
                title: 'Tienda no encontrada',
                text: 'El código de tienda no es válido.',
                icon: 'error',
                confirmButtonText: 'Aceptar',
            });
            setPromedios([]);
            setLoading(false);
            return;
        }

        try {
            const result = await Call('GET', `/api/promedios_comerssia/tienda/${value}/`, true, null, false);
            setPromedios(result?.data?.results[0]);
        } catch (error) {
            setPromedios([]);
        }

        setLoading(false);
    };

    const handleDownload = async () => {
        try {
            const response = await Call('GET', `/api/promedios_comerssia/${urlDowload}/`, true, null, false, null, true);

            const blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });

            saveAs(blob, 'promedios_tiendas.xlsx');
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
        }
    };

    return (
        ((user_cargo === COD_CARGO_GERENTE_ZONA) || (user_cargo === COD_CENTRO_COSTOS_COORDINADOR) || (all_tiendas && all_tiendas.length > 0)) && (
            <div className='card-general'>
                <h5>Indicadores comerciales</h5>
                <hr />
                <div className='mb-5'>
                    <span className='btn-general-accept cursor-pointer' onClick={handleDownload}>
                        Descargar información
                    </span>
                </div>
                <Select
                    placeholder="Tiendas"
                    optionFilterProp="children"
                    showSearch
                    style={{ width: 210 }}
                    onChange={handleChange}
                >
                    {tiendas.map((tienda, index) => (
                        <Option key={index} value={tienda.codigo}>
                            {tienda.nombre}
                        </Option>
                    ))}
                </Select>

                <Skeleton loading={loading}>
                    {cod_tienda ? (
                        <div className="cards-container" style={styles.cardsContainer}>
                            <div className="card-indicador p-3 border" style={styles.card}>
                                <h6>
                                    <i className="fas fa-solid fa-store"></i> Unidad promedio transacción (UPF)
                                </h6>
                                <p>
                                    {promedios?.promedios?.unidad_promedio_trasaccion_tienda?.toFixed(2) || "Sin datos"}
                                </p>
                                <h6>
                                    <i className="fas fa-solid fa-user-tie"></i> Promedio de valor por transacción (VPF)
                                </h6>
                                <p>
                                    {promedios?.promedios?.venta_por_transaccion_tienda !== undefined
                                        ? formatCurrency(promedios.promedios.venta_por_transaccion_tienda)
                                        : "Sin datos"}
                                </p>
                            </div>

                            <div className="card-indicador p-3 border" style={styles.card}>
                                <h6>
                                    <i className="fas fa-solid fa-store"></i> Precio promedio por unidad (PPU)
                                </h6>
                                <p>
                                    <strong>Venta total:</strong> {promedios?.venta_total_tienda !== undefined
                                        ? formatCurrency(promedios.venta_total_tienda)
                                        : "Sin datos"}
                                </p>
                                <p>
                                    <strong>Cantidad total:</strong> {promedios?.cantidad_total_tienda || "Sin datos"}
                                </p>
                                <p>
                                    <strong>Precio promedio:</strong> {promedios?.precio_promedio_tienda !== undefined
                                        ? formatCurrency(promedios.precio_promedio_tienda)
                                        : "Sin datos"}
                                </p>
                            </div>
                        </div>
                    ) : (
                        <p></p>
                    )}
                </Skeleton>
            </div>
        )
    );
};

const styles = {
    cardsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
        gap: '20px',
        justifyContent: 'center',
        marginTop: '20px',
    },
    card: {
        textAlign: 'center',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '20px',
    },
};

export default TiendasSelect;
