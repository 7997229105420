import React from 'react';
import { Button } from 'antd';

const TallasButtons = ({ tallas, tallaSeleccionada, onTallaClick }) => (
    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center', gap: '10px', flexWrap: 'wrap' }}>
        {tallas.map((talla) => (
            <Button 
                key={talla} 
                style={{
                    backgroundColor: tallaSeleccionada === talla ? 'red' : '',
                    color: tallaSeleccionada === talla ? 'white' : '',
                    borderColor: tallaSeleccionada === talla ? 'red' : '',
                    fontWeight: 'bold'
                }}
                onClick={() => onTallaClick(talla)}
            >
                {talla}
            </Button>
        ))}
    </div>
);

export default TallasButtons;
