import React, { useState } from 'react';
import { Button, message } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

const DaySelector = ({ onDayChange, onRefresh }) => {
  const [selectedDay, setSelectedDay] = useState(1);
  const [messageApi, contextHolder] = message.useMessage();

  const handleDayChange = (day) => {
    setSelectedDay(day);
    onDayChange(day);
  };

  const handleRefreshClick = () => {
    messageApi
      .open({
        type: 'loading',
        content: 'Actualizando...',
        duration: 2,
      })
      .then(() => {
        onRefresh(selectedDay);
        return messageApi.success('Actualización completada', 2);
      });
  };


  return (
    <div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
      {contextHolder}
      <Button
        type="primary"
        size="large"
        style={{
          backgroundColor: selectedDay === 1 ? 'red' : 'gray',
          borderColor: selectedDay === 1 ? 'red' : 'gray',
        }}
        onClick={() => handleDayChange(1)}
      >
        Hoy
      </Button>
      <Button
        type="primary"
        size="large"
        style={{
          backgroundColor: selectedDay === 2 ? 'red' : 'gray',
          borderColor: selectedDay === 2 ? 'red' : 'gray',
        }}
        onClick={() => handleDayChange(2)}
      >
        Ayer
      </Button>
      <Button
        type="default"
        size="large"
        icon={<ReloadOutlined />}
        onClick={handleRefreshClick}
      >
      </Button>
    </div>
  );
};

export default DaySelector;
