import React, { useState } from 'react';
import { Modal, Empty } from 'antd';

const DetailModal = ({ isVisible, data, onClose }) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <Modal
      title="Detalles del Inventario"
      visible={isVisible}
      onCancel={onClose}
      footer={null}
    >
      <div style={{ textAlign: 'center' }}>
        {imageError || !data.imagen ? (
          <Empty description="No se encontró una imagen" />
        ) : (
          <img
            src={data.imagen}
            alt="Imagen del Producto"
            style={{ width: '100%', maxWidth: '300px', height: 'auto', margin: '0 auto' }}
            onError={handleImageError}
          />
        )}
        
        <p><strong>Nombre:</strong> {data.nombre}</p>
        <p><strong>Venta:</strong> {data.venta}</p>
        <p><strong>Inventario:</strong> {data.inventario}</p>
      </div>
    </Modal>
  );
};

export default DetailModal;
