import React, {useState , useEffect } from 'react'
import Spinner from '../../base/spiner'
import { TitleLayout } from '../../base/TitleLayout'
import { Select, Input, Table } from 'antd';
import DataTableComp from '../../produccion/autoriaLotes/dataTableComp';
import Call from '../../../config/Call'
import Swal from 'sweetalert2'

const { Option } = Select;

const SellersIndex = () => {

    const [sp, setSp] = useState(false)
    let datat = {
        titulo: "Presupuesto vendedor" //SECTION TITLE
    }
    const [listUser, setListUser]=useState([])// LIST SELLERS
    const [listMonths, setListMonths ] =useState([]) //LIST MONTHS ACTIVES
    const [listStores, setListStores ] =useState([]) // LIST STORES
    const [getMonthly_budget, setGetMonthly_budget] = useState(0) // STORE MONTHLY BUDGET 
    const [textZone, setTextZone]= useState('')
    const [sumMonthlyBudget, setSumMonthlyBudget] = useState(0) // SUM INDIVIDUAL BUDGET 
    const [sumMonthly_percentage, setSumMonthly_percentage]= useState(0)
    const [dataSelects, setDataSelects]=useState({
        month:null, //DATA SELECTS
        store:null
    })
    const [ mode, setMode ] = useState('edit') //VIEW MODE
    const [actionUpdate, setActionUpdate ] = useState(false) //ADD UPDATE IN DATA
    const [showButton, setShowButton] = useState(false) //SHOWBUTTONS
    const [showError, setShowError]= useState('') // SHOW/HIDE ERRORS IN DAYS
    const [ filterText, setFilterText ] = useState('')
    const [listUser2, setListUser2]=useState([])
    const [showInfo, setShowInfo]=useState(false)
    const [nextMonthState, setNextMonthState]= useState(null)

    useEffect(()=>{
        getZone() //LIST ZONE USER
    }, [])

    const getZone=async()=>{
        //lIST ZONE USERS
        try {
            setSp(true)
            const res= await Call('GET', '/api/budgets/zoneslist', true, null, false)
            if(res.data.data.length>0){
                setTextZone(res.data.data[0])
                getListStores()
            }
        } catch (error) {
            setSp(false)
        }
    }
    const getMonths=async(store)=>{
        //LIST MONTHS AVAILABLE
        try {
            setSp(true)
            const res= await Call('GET', '/api/budgets/monthlist/'+store, true, null, false)
            setListMonths(res.data.data)
            setSp(false)
        } catch (error) {
            setSp(false)
        }
    }
    const getListStores =async()=>{
        //LIST  STORES AVAILABLE
        try {
            setSp(true)
            const res = await Call('GET', 'api/budgets/storelist', true, null, false)
            
            setListStores(res.data.data)
            setSp(false)
        } catch (error) {
            setSp(false)
        }
    }

    const getListUser = async(month)=>{
        //GET LIST EMPLOYES
        
        try {
            setSp(true)
            const res = await Call('POST', '/api/budgets/employeelist', true, {month:month, store:dataSelects.store}, false)
            for(let i=0; i<res.data.data.document_data_list.length; i++){
                res.data.data.document_data_list[i].total_days = (parseInt( res.data.data.document_data_list[i].final_day)-parseInt( res.data.data.document_data_list[i].initial_day)+1)
            }   
            setListUser(res.data.data.document_data_list)
            setListUser2(res.data.data.document_data_list)
            setGetMonthly_budget(res.data.data.monthly_budget)
            getSumMonthlyBudget(res.data.data.document_data_list)
            getSumMonthlyPercentage(res.data.data.document_data_list)
            let suma=0
            res.data.data.document_data_list.forEach((element) => (suma += element.monthly_percentage));
            
            //CHANGE VIEW MODE
            let nextMonth=((new Date().getMonth())+2)
            setNextMonthState(nextMonth)
            if(res.data.data.monthly_budget !== null){
                if(suma > 0){
                    setMode('save')
                }else{
                    if((((new Date().getMonth())+1) !== month) && (nextMonth !== month) ){
                        setMode('save')
                    }else{
                        setMode('edit')
                    }
                }
            }else{
                setMode('save')
            }
            setSp(false)
        } catch (error) {
            setSp(false)
        }
    }
    const handleChange = (e, index, row)=>{
        //CHANGE IN INPUTS
        let data= [...listUser]
       if(e.target.name=== 'daily_percentage'){
           const userTeam= data.findIndex(item=> (item.document === row.document))

           if (userTeam !== -1) {
               data[userTeam].daily_percentage = e.target.value;
               data[userTeam].update = true;
           }

            if(e.target.value<0){
                setShowError('El porcentaje diario no puede ser negativo')
            }else{
                setShowError('')
            }
       }
       if(e.target.name=== 'initial_day'){
           const userTeam= data.findIndex(item=> (item.document === row.document))
           if (userTeam !== -1) {
               data[userTeam].initial_day =parseInt(e.target.value)
           }

        if((e.target.value !== '') && (data[userTeam].final_day !== '') && (parseInt(e.target.value)<=parseInt(data[userTeam].final_day))){
            data[userTeam].total_days=((parseInt(data[userTeam].final_day)-parseInt(e.target.value))+1)
            setShowError('')  
        }else{
            if(parseInt(e.target.value)>parseInt(data[userTeam].final_day)){
                data[userTeam].total_days=''
                setShowError('El día inicial no puede ser mayor al día final ')    
            }else{
                setShowError('')  
                data[userTeam].total_days=''
            }
        }
       }
       if(e.target.name=== 'final_day'){
           const userTeam= data.findIndex(item=> (item.document === row.document))
           if (userTeam !== -1) {
               data[userTeam].final_day =parseInt(e.target.value)
           }

        if(e.target.value !== '' && data[userTeam].initial_day !== '' && (parseInt(data[userTeam].initial_day)<=parseInt(e.target.value))){
            data[userTeam].total_days=((parseInt(e.target.value)-parseInt(data[userTeam].initial_day))+1)
            setShowError('') 
        }else{
            if(parseInt(data[userTeam].initial_day)>parseInt(e.target.value)){
                data[userTeam].total_days=''
                setShowError('El día inicial no puede ser mayor al día final ')    
            }else{
                setShowError('')  
                data[userTeam].total_days=''
            }
        }
       }
       setListUser(data)
       validateDay(data)
       if(actionUpdate){
           const userTeam= data.findIndex(item=> (item.document === row.document))
            data[userTeam].update=true
       }
       setShowButton(false)      
    }
    const calculateData = async()=>{
        //CALCULATE DATA EMPLOYES
        let array=[]
        let data={}
        
        for(let i=0; i<listUser.length; i++){
            if(listUser[i].update){
        
                data={
                    document: listUser[i].document,
                    name:listUser[i].name,
                    position: listUser[i].position,
                    daily_percentage: parseFloat(listUser[i].daily_percentage),
                    initial_day: parseInt(listUser[i].initial_day),
                    final_day: parseInt(listUser[i].final_day),
                    total_days: (listUser[i].final_day-listUser[i].initial_day)+1,
                    update: listUser[i].update
                }
                array.push(data)
            }
        }
        
        const result = array.filter((item) => item.update!== undefined)
        
        try {
            setSp(true)
            const res = await Call('POST', 'api/budgets/calculations/'+dataSelects.month+'/'+dataSelects.store, true, {data:array}, false)
            for(let i=0; i<res.data.data.document_data_list.length; i++){
                for(let j=0; j<result.length; j++){
                    if(res.data.data.document_data_list[i].document === result[j].document){
                        res.data.data.document_data_list[i].update = true
                    }
                }
            }
            setListUser(res.data.data.document_data_list)
            getSumMonthlyBudget(res.data.data.document_data_list)
            getSumMonthlyPercentage(res.data.data.document_data_list)
            setSp(false)
            setShowButton(true)
        } catch (error) {
            setSp(false)
        }
    }
    const getSumMonthlyBudget=(list)=>{
        //SUMMARY MONTHLY BUDGETS 
        let suma=0
        list.forEach((element) => (suma += parseFloat(element.individual_monthly_budget)));
        setSumMonthlyBudget(suma)
    }
    const getSumMonthlyPercentage=(list)=>{
         //SUMMARY PERCENTAGE BUDGETS 
        let suma=0
        list.forEach((element) => (suma += element.monthly_percentage));
        setSumMonthly_percentage(suma)
    }
    const saveBudget=async()=>{
        //SAVE BUDGET
        if(sumMonthly_percentage < 100){
            Swal.fire({
                title: "",
                html:'<div class="fs-18 fw-600">El presupuesto mensual no debe ser menor al 100%</div><div>¿Desea guardar el presupuesto?</div>',
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Guardar",
                cancelButtonText:'Cancelar'
              }).then(async(result) => {
                    let array=[]
                    let data={}
                    for(let i=0; i<listUser.length; i++){
                        if(listUser[i].daily_percentage>0){
                            data={
                                document: listUser[i].document,
                                name:listUser[i].name,
                                position: listUser[i].position,
                                daily_percentage: parseFloat(listUser[i].daily_percentage),
                                initial_day: parseInt(listUser[i].initial_day),
                                final_day: parseInt(listUser[i].final_day),
                                total_days: (listUser[i].final_day-listUser[i].initial_day)+1,
                                individual_monthly_budget:listUser[i].individual_monthly_budget,
                                monthly_percentage: listUser[i].monthly_percentage,
                            }
                            array.push(data)
                        }
                        
                        
                    }
                    if (result.isConfirmed){
                        try {
                            setSp(true)
                            const res = await Call('POST', `api/budgets/save/${dataSelects.month}/${dataSelects.store}`, true, {data:array, percentage: sumMonthly_percentage} , false)
                            if(res.status === 200){
                                setSp(false)
                                setListUser(res.data.data.document_data_list)
                                setMode('save')
                                getSumMonthlyPercentage(res.data.data.document_data_list)
                                getSumMonthlyBudget(res.data.data.document_data_list)
                                if (result.isConfirmed) {
                                    Swal.fire({
                                    title: "",
                                    text: "¡Presupuesto se ha enviado a comerssia!",
                                    icon: "success",
                                    confirmButtonText:'Aceptar',
                                    });
                                }
                            }else{
                                setSp(false)
                            }
                        } catch (error) {
                            setSp(false)
                        }
                    }
                    
              })
        }else{
            Swal.fire({
                title: "",
                text: "¿Está seguro de guardar el presupuesto?",
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Guardar",
                cancelButtonText:'Cancelar'
            }).then(async(result) => {
                let array=[]
                let data={}
                for(let i=0; i<listUser.length; i++){
                    data={
                        document: listUser[i].document,
                        name:listUser[i].name,
                        position: listUser[i].position,
                        daily_percentage: parseFloat(listUser[i].daily_percentage),
                        initial_day: parseInt(listUser[i].initial_day),
                        final_day: parseInt(listUser[i].final_day),
                        total_days: (listUser[i].final_day-listUser[i].initial_day)+1,
                        individual_monthly_budget:listUser[i].individual_monthly_budget,
                        monthly_percentage: listUser[i].monthly_percentage,
                    }
                    array.push(data)
                }
                if(result.isConfirmed){
                    try {
                        setSp(true)
                        const res = await Call('POST', `api/budgets/save/${dataSelects.month}/${dataSelects.store}`, true, {data:array, percentage: sumMonthly_percentage} , false)
                        if(res.status === 200){
                            setSp(false)
                        
                            if (result.isConfirmed) {
                                setListUser(res.data.data.document_data_list)
                                getSumMonthlyPercentage(res.data.data.document_data_list)
                                getSumMonthlyBudget(res.data.data.document_data_list)
                                setMode('save')
                                Swal.fire({
                                title: "",
                                text: "¡Presupuesto se ha enviado a comerssia!",
                                icon: "success",
                                confirmButtonText:'Aceptar',
                                });
                            }
                            
                        }else{
                            setSp(false)
                        }
                    } catch (error) {
                        setSp(false)
                    } 
                }
                               
            });
        }
    }
    const saveUpdateBudget = async()=>{
        //EDIT BUDGET
        if(sumMonthly_percentage < 100){
            Swal.fire({
                title: "",
                html:'<div class="fs-18 fw-600">El presupuesto mensual no debe ser menor al 100%</div><div>¿Desea guardar el presupuesto?</div>',
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Guardar",
                cancelButtonText:'Cancelar'
              }).then(async(result) => {
                
                    let array=[]
                    let data={}
                    for(let i=0; i<listUser.length; i++){
                        if(listUser[i].update){
                            data={
                                document: listUser[i].document,
                                name:listUser[i].name,
                                position: listUser[i].position,
                                daily_percentage: parseFloat(listUser[i].daily_percentage),
                                initial_day: parseInt(listUser[i].initial_day),
                                final_day: parseInt(listUser[i].final_day),
                                total_days: (listUser[i].final_day-listUser[i].initial_day)+1,
                                individual_monthly_budget:listUser[i].individual_monthly_budget,
                                monthly_percentage: listUser[i].monthly_percentage,
                            }
                            array.push(data)
                        } 
                    }
                    if(array.length > 0){
                        if(result.isConfirmed){
                            try {
                                setSp(true)
                                const res = await Call('PUT', `api/budgets/save/${dataSelects.month}/${dataSelects.store}`, true, {data:array, percentage: sumMonthly_percentage} , false)
                                if(res.status === 200){
                                    setSp(false)
                                    setListUser(res.data.data.document_data_list)
                                    
                                    setActionUpdate(false)
                                    setMode('save')
                                    if (result.isConfirmed) {
                                        Swal.fire({
                                        title: "",
                                        text: "¡Presupuesto se ha enviado a comerssia!",
                                        icon: "success",
                                        confirmButtonText:'Aceptar',
                                        });
                                    }
                                }else{
                                    setSp(false)
                                }
                            } catch (error) {
                                setSp(false)
                            }
                        }
                    }else{
                        Swal.fire({
                            title: "",
                            text: "No se han realizado ningún cambio",
                            icon: "info",
                            confirmButtonText:'Aceptar',
                            });
                    }
                    
              })
        }else{
            Swal.fire({
                title: "",
                text: "¿Está seguro de guardar el presupuesto?",
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Guardar",
                cancelButtonText:'Cancelar'
            }).then(async(result) => {
                
                let array=[]
                let data={}
                for(let i=0; i<listUser.length; i++){
                    if(listUser[i].update){
                        data={
                            document: listUser[i].document,
                            name:listUser[i].name,
                            position: listUser[i].position,
                            daily_percentage: parseFloat(listUser[i].daily_percentage),
                            initial_day: parseInt(listUser[i].initial_day),
                            final_day: parseInt(listUser[i].final_day),
                            total_days: (listUser[i].final_day-listUser[i].initial_day)+1,
                            individual_monthly_budget:listUser[i].individual_monthly_budget,
                            monthly_percentage: listUser[i].monthly_percentage,
                        }
                        array.push(data)
                    } 
                }
                if (result.isConfirmed) {
                    try {
                        setSp(true)
                            const res = await Call('PUT', `api/budgets/save/${dataSelects.month}/${dataSelects.store}`, true, {data:array, percentage: sumMonthly_percentage} , false)
                            if(res.status === 200){
                                setSp(false)
                                    setListUser(res.data.data.document_data_list)
                                    Swal.fire({
                                    title: "",
                                    text: "¡Presupuesto se ha enviado a comerssia!",
                                    icon: "success",
                                    confirmButtonText:'Aceptar',
                                    });
                                
                                setMode('save')
                            }else{
                                setSp(false)
                            }
                        
                    } catch (error) {
                        setSp(false)
                    } 
                }               
            });
        }
    }
    //SELECT
    const onChange = (option, children) => {
        //CHANGES IN SELECTS
        setDataSelects({
            ...dataSelects,
            [children.name]:option
        })
        if((children.name === 'store') && (option !== null && option !== '')){
            setDataSelects({
                ...dataSelects,
                month:null,
                store:option
            })
            setSumMonthlyBudget(0)
            setSumMonthly_percentage(0)
            getMonths(option)
            setListUser(null)
            setActionUpdate(false)
            setShowInfo(false)
        }
        if((children.name === 'month') && (option !== null && option !== '')){
            setListUser([])
            getListUser(option)
            setActionUpdate(false)
            setShowInfo(true)
        }
    };
    const validateDay=(data)=>{
        //VALIDATE TOTAL DAYS
        let v= data.filter(item=> item.total_days<0)
        if(v.length>0){
            setShowError('El día inicial no puede ser menor al día final')
        }
    }
    //DATATABLE
    const column=[
        {
            name: 'CC',
            cell: row => (
                <div>
                    {row.document}
                </div>
            ),
            // width: '140px',
            filterable: true,
        },
        {
            name: 'Nombre',
            cell: row => (
                <div className='text-capitalize'>
                    {row.name.toLowerCase()}
                </div>
            ),
            // width: '140px',
            filterable: true,
        },
        {
            name: 'Cargo',
            cell: row => (
                <div>
                    {row.position&& row.position}
                </div>
            ),
            // width: '140px',
            filterable: true,
        },
        {
            name: '% Diario',
            cell: (row, index) => (
                <div>
                    <input type="number" 
                        className='form-control form-control-sm' 
                        value={row.daily_percentage && row.daily_percentage} 
                        id="daily_percentage" 
                        onChange={(e)=>handleChange(e,index, row)}
                        name="daily_percentage" 
                        min={0}
                        max={100}
                        disabled={((mode === 'save')|| ((new Date().getDate()>5) && (((new Date().getMonth())) === dataSelects.month)))? true: false}
                    />
                </div>
            ),
            // width: '140px',
            filterable: true,
        },
        {
            name: 'Día inicial',
            cell: (row, index) => (
                <div>
                    <input type="number" 
                        className='form-control form-control-sm' 
                        value={row.initial_day} 
                        id="initial_day" 
                        name="initial_day" 
                        onChange={(e)=>handleChange(e,index, row)}
                        min={1}
                        max={31}
                        disabled={((mode === 'save') || ((new Date().getDate()>5) && (((new Date().getMonth())) === dataSelects.month)))? true: false}
                        />
                </div>
            ),
            // width: '140px',
            filterable: true,
        },
        {
            name: 'Día final',
            cell: (row, index) => (
                <div>
                    <input type="number" 
                        className='form-control form-control-sm' 
                        value={row.final_day} 
                        onChange={(e)=>handleChange(e,index, row)}
                        id="final_day" 
                        name="final_day" 
                        min={1}
                        max={31}
                        disabled={((mode === 'save') || ((new Date().getDate()>5) && (((new Date().getMonth())) === dataSelects.month)))? true: false}
                    />
                </div>
            ),
            // width: '140px',
            filterable: true,
        },
        {
            name: 'Total días',
            cell: row => (
                <div>
                    {row.total_days}
                </div>
            ),
            // width: '140px',
            filterable: true,
        },
        {
            name: 'Ppto mensual',
            cell: row => (
                <div>
                    ${row.individual_monthly_budget&& Intl.NumberFormat().format(row.individual_monthly_budget)}
                </div>
            ),
            // width: '140px',
            filterable: true,
        },
        {
            name: '% Mensual',
            cell: row => (
                <div>
                    {row.monthly_percentage}
                </div>
            ),
            // width: '140px',
            filterable: true,
        },
    ]
    
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    }; 
    const handletext =(e)=>{
        
        setFilterText(e.target.value)
        let nuevo = (listUser2.filter(item => (item.document || item.name) &&( item.document.includes(e.target.value) || item.name.toLowerCase().includes(e.target.value.toLowerCase()))))
        
        setListUser(nuevo)
        if(e.target.value === ''){
            setListUser(listUser2)
        }
    }
    const handleClear = () => {
        
        if (filterText) {
            // setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
            setListUser(listUser2)
            // setFilteredItems(null)
        }
    };
    const subHeaderComponentMemo2 = React.useMemo(() => {
        return (
            <>
            <input type="text" className="form-control form-control-sm col col-sm-3" 
                    placeholder="Buscar vendedor" 
                    aria-label="Buscar" 
                    aria-describedby="basic-addon1" 
                    onChange={handletext} 
                    value={filterText} 
                />
                { filterText !== '' &&
                    <div type="button" className='pl-2 cursor-pointer' 
                        onClick={handleClear} >
                        <i className="fas fa-times-circle"></i>
                    </div>
                }
    
            </>
        );
    
    });
    
    //END DATATABLE

    const expandedRowRender = (record, index) => {
        //SCHEMA DATA MOVIL
        return(
            <div className='py-2 d-block text-left fs-18' key={index}>
                <div className='my-1'>
                    <span className='fw-600'>CC: </span> {record.document} 
                </div>
                <div className='my-1'>
                    <span className='fw-600 my-1'>% Diario</span>
                    <Input 
                        id="daily_percentage" 
                        name="daily_percentage" 
                        value={record.daily_percentage}
                        onChange={(e)=>handleChange(e,index,record)}
                        disabled={mode === 'save'? true: false}
                    />
                </div>
                <div className='my-1'>
                    <span className='fw-600 my-1'>Día inicial: </span>
                    <input type="number" 
                        className='form-control form-control-sm' 
                        value={record.initial_day} 
                        id="initial_day" 
                        name="initial_day" 
                        onChange={(e)=>handleChange(e,index, record)}
                        min={1}
                        max={31}
                        disabled={mode === 'save'? true: false}
                    />
                </div>
                <div className='my-1'>
                    <span className='fw-600'>Día final:</span>
                    <input type="number" 
                        className='form-control form-control-sm' 
                        value={record.final_day} 
                        onChange={(e)=>handleChange(e,index, record)}
                        id="final_day" 
                        name="final_day" 
                        min={1}
                        max={31}
                        disabled={mode === 'save'? true: false}
                    />
                </div>
                <div className='my-1'>
                    <span className='fw-600'>Total días:  </span> 
                    {(record.final_day-record.initial_day)+1}
                </div>
                <div className='my-1'>
                    <span className='fw-600'>Presupuesto mensual:  </span>
                    ${record.individual_monthly_budget&& Intl.NumberFormat().format(record.individual_monthly_budget)}
                </div>
                <div className='my-1'>
                    <span className='fw-600'>% Mensual:  </span>
                    {record.monthly_percentage}
                </div>

            </div>
            )
    };
    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {return(<div className='text-capitalize fs-16'>{record.name&& record.name.toLowerCase()}</div>)},
        },
        {
            title: 'Documento',
            dataIndex: 'document',
            key: 'document',
            className:'w-30'
        },
    ];
    const customStyles = {
        pagination: {
            style: {
                fontSize: '16px',
                color: '#000000',
            },
            pageButtonsStyle: {
                color: '#000000',
            },
        },
    }
        return (
        <div>
            { sp ? <Spinner /> : ''}  {/*LOADER*/}
            <TitleLayout data={datat} clase={"text-left"} icon="true" seccion="prepvendedores"/> {/*-----TITULO VISTA------*/}
            <div className='bg-white col-lg-12 col-md-12 col-sm-12 mx-auto p-3' style={{minHeight:'60vh', paddingBottom:'1rem'}}>
                <div>
                    <span className='fw-600 text-capitalize'>{textZone&& textZone.toLowerCase()}</span> 
                </div>
                <section className='mt-4 d-flex flex-wrap' style={{gap: '10px'}}>
                    <div className='mr-2'>
                        <Select
                            showSearch
                            placeholder="Tienda"
                            optionFilterProp="children"
                            onChange={(option, children)=>onChange(option, children)}
                            filterOption={(input, option) =>
                                (option.children).toLowerCase().includes(input.toLowerCase())
                            }
                            value={dataSelects.store&& dataSelects.store}
                            style={{width:'200px'}}
                        >
                            {
                                listStores&&
                                listStores.map((s, indexs)=>(
                                    <Option key={indexs} name="store" value={s.store_code} className='text-capitalize' >{s.store_name&& s.store_name.toLowerCase() }</Option>
                                ))
                            }
                        </Select>
                    </div>
                    { (dataSelects.store !== null && dataSelects.store !== '') &&
                        <div className='mr-3 ml-0'>
                            <Select
                                showSearch
                                placeholder={"Mes"}
                                optionFilterProp="children"
                                onChange={(option, children)=>onChange(option, children)}
                                filterOption={(input, option) =>
                                    (option.children).toLowerCase().includes(input.toLowerCase())
                                }
                                value={dataSelects.month&& dataSelects.month}
                                style={{width:'100px'}}
                            >
                                {listMonths&&
                                    listMonths.map((m, index)=>(
                                        <Option key={index} name="month" value={m.id_month}>{m.month_name}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                    }
                    
                </section>
                
                { listUser2&& (listUser2.length !== 0 && showInfo) &&
                    <>
                        <section className='mt-4 flex-wrap card-budget'>
                            <div className='card-general'>
                                <div className='fw-600 mb-2'>Presupuesto tienda</div> 
                                <div className='fs-18 fw-600'>{getMonthly_budget&& '$'+new Intl.NumberFormat().format(getMonthly_budget)}</div>
                            </div>
                            <div className='card-general'>
                                <div className='fw-600 mb-2'>Total asignado</div> 
                                <div className='fs-18 fw-600'> ${sumMonthlyBudget&& Intl.NumberFormat().format(sumMonthlyBudget)}</div>
                            </div>
                            <div className='card-general'>
                                <div className='fw-600 mb-2'>Porcentaje asignado</div> 
                                <div className={`fs-18 fw-600 ${sumMonthly_percentage&& (sumMonthly_percentage < 100 || sumMonthly_percentage > 100) ? 'text-danger': 'text-success'}`}>{sumMonthly_percentage&& (sumMonthly_percentage.toFixed(2))}%</div>
                            </div>
                        </section>
                        <div className='mt-4 text-center'>
                            {sumMonthly_percentage&& sumMonthly_percentage> 100 ? <span className='text-danger fs-16 fw-600'> El porcentaje mensual asignado no puede ser mayor a 100, reasigna los porcentajes</span>:''}
                            {showError !== '' ? <span className='text-danger fs-16 fw-600'>{showError}</span>:''}
                        </div>
                        {
                            (mode === 'save' && (((new Date().getMonth())+1) === dataSelects.month) || (((new Date().getMonth()) === dataSelects.month) && (new Date().getDate()<=6))) ?
                            <section className='text-right text-info fw-600 text-underline'>
                                <span className='cursor-pointer' onClick={()=>{setMode('edit'); setActionUpdate(true)}}>Editar</span>
                            </section>
                            :''
                        }
                            
                        <div className='ocultar-movil'>
                            <div className='mt-4'>
                                <DataTableComp 
                                    columns={column}
                                    data={listUser&& listUser}
                                    paginationPerPage={20}
                                    paginationComponentOptions={paginationComponentOptions}
                                    subHeaderComponent={subHeaderComponentMemo2}
                                    customStyles={customStyles}
                                />
                            </div>
                        </div>
                        <div className='mostrar-movil'>
                            <div className='mt-4 mb-3 d-flex'>
                                <input type="text" className="form-control form-control-sm col-sm-2 w-90" 
                                        placeholder="Buscar vendedor" 
                                        aria-label="Buscar" 
                                        aria-describedby="basic-addon1" 
                                        onChange={handletext} 
                                        value={filterText} 
                                    />
                                    { filterText !== '' &&
                                        <div type="button" className='pl-2 cursor-pointer' 
                                            onClick={handleClear} >
                                            <i className="fas fa-times-circle"></i>
                                        </div>
                                    }
                            </div>
                        <Table
                                size='middle'
                                columns={columns}
                                expandable={{
                                expandedRowRender,
                                }}
                                pagination={{
                                    total: (listUser&& listUser.length),
                                    pageSize: 20, // Número de filas por página
                                    showSizeChanger: true,
                                }}
                                dataSource={listUser && listUser.map((item, index) => ({
                                    ...item,
                                    key: index}))}
                                locale={{
                                    emptyText: 'No hay datos para mostrar',
                                    }}
                            />

                        </div>
                        
                        { !showError &&
                            mode=='edit' &&
                            (((((new Date().getMonth())+1) === dataSelects.month) || (nextMonthState === dataSelects.month)) && (getMonthly_budget&& getMonthly_budget >0)  ||
                            ((new Date().getDate()>=28) && (nextMonthState === dataSelects.month))|| // NEXT MONTH IN 28 DAYS ACTUALLY MONTH
                            ((new Date().getMonth()) === dataSelects.month && (new Date().getDate()<=5))) //LAST MONTH
                             ?

                            <section className='d-flex justify-content-center'>
                                <div 
                                    onClick={calculateData} 
                                    className='btn-info2 fit-content m-1' 
                                    style={{paddingTop:'5px', paddingBottom:'5px'}}
                                > 
                                    Calcular
                                </div>
                                {
                                (showButton &&sumMonthly_percentage&& sumMonthly_percentage <= 100 && sumMonthly_percentage !== 0 ) ?
                                    <div 
                                        onClick={actionUpdate ? saveUpdateBudget : saveBudget}
                                        className='btn-buscar px-4 fw-400 cursor-pointer m-0 height-fit m-1'>
                                            Guardar
                                    </div>
                                    :''
                                }
                            
                            </section>
                            :''
                        }
                               
                    </>
                }
            </div>
        </div>
    )
}
export default SellersIndex;
