import React, {useState,useEffect} from 'react';
import { Link } from "react-router-dom";
import {TitleLayout} from '../../base/TitleLayout'
import Spinner from '../../base/spiner';
import Call from '../../../config/Call'
import { Select } from 'antd';
import DataTableComp from '../../produccion/autoriaLotes/dataTableComp'
import Swal from 'sweetalert2'
import $ from 'jquery';
import Transfer  from './components/transfer';
import History from './components/history';
import Masivas from './components/masivas';
const { Option } = Select;



const Index =(props)=>{
    
    const [sp, setSp]= useState(false) // MOSTRAR/OCULTAR LOADER
    // const [time, setTime] =useState('')
    const [busqueda, setBusqueda] = useState(false)
    const [listBodegas, setListBodegas] = useState([])
    const [dataTable, setDataTable] =useState([])
    const [showModalChange, setShowModalChange ]=useState(false)
    const [newAmount, setNewAmount]=useState('')
    const [ errorEmpty, setErrorEmpty ] = useState(null)
    const [listTypesOrigin, setListTypesOrigin ]=useState([])
    const [consultar, setConsultar] = useState({
        bodega: '',
        codigo: ''
    })
    
    const [activeTab, setActiveTab] = useState('ubicar');

    const [searchCode, setSearchCode ] = useState('') //save search data
    let datat = {
        titulo: "Consultar ubicaciones"
    }

    const [selectedBodegaId, setSelectedBodegaId] = useState('');

    //////// DELETE //////
    const [ type, setType ] = useState('')
    const [showSearchs, setShowSearchs] = useState(false); //Show searchs
    const [listTypes, setListTypes] = useState([]);
    let    [checkedList, setCheckedList] = useState([]);
    const [allChecked, setAllChecked]= useState(false)
    const [buscar, setBuscar]= useState('')
    const [time, setTime] =useState('') //MODIFICAR TIME
    const [dataRef, setDataRef]=useState([])
    ////// END DELETE
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    }

    useEffect(()=>{
        listarBodegas()
    }, [])
    useEffect(()=>{
        
    }, [sp])

    const listarBodegas = async()=>{
        setSp(true)
        //LISTA DE BODEGAS
        const res = await Call('GET', 'api/bodegas/cedis', true, null, false)
        setListBodegas(res.data.data)
        setSp(false)
    }

    const handleChange = (e,a)=>{
        setBusqueda(false)
        setListTypes([])
        setListTypesOrigin([])
        setType('')
        setBuscar('')
        setShowSearchs(false)
        if(a === undefined){
            setSearchCode('') //clean search data
             setConsultar({
                 ...consultar,
                 [e.target.name]: e.target.value,
             })                
        }
        if(a){
         
            setConsultar({
                 ...consultar,
                 [a.name]: a.value,
                 codigo:''
             }) 

             setSelectedBodegaId(a.value);
             setDataTable([])
        } 
     }

     const consultarUbicaciones = async ()=>{
        setSp(true)
        let info={
            codigo:consultar.codigo
        }
        setSearchCode(consultar.codigo)
        const res= await Call('POST', '/api/bodegas/ubicacion/unidades/'+consultar.bodega, true, info, false)

        if(res.data.code === 1){
            setDataTable(res.data.data)
            setConsultar({
                ...consultar,
                codigo:''
            })
            setBusqueda(true)
        }else{
            Swal.fire({
                title: '',
                icon: 'error',
                html: res.data.message,
                focusConfirm: false,
                confirmButtonText:'Aceptar',
            })
            setConsultar({
                ...consultar,
                codigo:''
            })
            setSearchCode('')
        }
        setSp(false)
     }
     const [dataSelected , setDataSelected ] = useState({})
     const dataChange=(data)=>{
       setDataSelected(data)
     }
     const ConfirmLocation = async()=>{
        if(newAmount !=='' ){
            setErrorEmpty(false)
            setSp(true)
            try {
                
                const res = await Call('GET', `api/bodegas/quitar/${dataSelected.referencia}/${dataSelected.ubicacion}/${newAmount}/${consultar.bodega}/${searchCode}`, true, null, false)
                
                if(res.data.code === 1){
                    setDataTable([])
                  
                    $('.modal-backdrop').remove()
                    const modals = document.querySelector('body.modal-open');

                    if(modals){
                        modals.classList.remove('modal-open')
                    }
                    
                    setShowModalChange(false)
                    setDataSelected({
                        
                    })
                    setNewAmount('')
                    setSp(false)
                    setDataTable(res.data.data)
                    Swal.fire({
                        title: '',
                        icon: 'success',
                        html: res.data.message,
                        focusConfirm: false,
                        confirmButtonText:'Aceptar',
                    })
                   
                }else{
                    setSp(false)
                    
                    Swal.fire({
                        title: '',
                        icon: 'error',
                        html: res.data.message,
                        focusConfirm: false,
                        confirmButtonText:'Aceptar',
                    })
                   
                }
                
            } catch (error) {
                setSp(false)
                setNewAmount('')
                
                Swal.fire({
                    title: '',
                    icon: 'error',
                    html: error.response.data.message,
                    focusConfirm: false,
                    confirmButtonText:'Aceptar',
                })
                
            }
        }else{
            setErrorEmpty(true)
        }
     }
    
     const columns=[
        {
            name: 'Referencia',
            cell: row => (
                <div>
                    {row.referencia === '' ? '-' : row.referencia}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Ubicación',
            cell: row => (
                <div>
                    {row.ubicacion === '' ? '-' : row.ubicacion}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Cantidad',
            cell: row => (
                <div>
                    {row.cantidad}
                </div>
            ),
            width: "100px",
            filterable: true,
        },

     ]
     const columnsPpal=[
        {
            name: 'Referencia',
            cell: row => (
                <div>
                    {row.referencia === '' ? '-' : row.referencia}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Ubicación',
            cell: row => (
                <div>
                    {row.ubicacion === '' ? '-' : row.ubicacion}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Cantidad',
            cell: row => (
                <div>
                    {row.cantidad}
                </div>
            ),
            width: "100px",
            filterable: true,
        },
        {
            name: 'Acción',
            cell: row => (
                <div>
                    <i data-toggle="modal" data-target="#exampleModal" className="cursor-pointer fas fa-times text-danger fs-18" onClick={()=>{setShowModalChange(true); dataChange(row)}}></i>
                </div>
            ),
            width: "100px",
            filterable: true,
        }

     ]

     //// INIT DELETED /////
     const getListTypes= async(types)=>{
        try {
            setSp(true);
            
            const cedi_id = (listBodegas.length> 1? selectedBodegaId: listBodegas[0].id)
            const res = await Call('GET', `/api/bodegas/listado_eliminar/${types}/${cedi_id}/eliminar`, true, null, false);
            if(types === 'estanterias'){
                let arr=[]
                for(let i in res.data.data){
                    let data={
                        item:res.data.data[i],
                        status: false
                    }
                    arr.push(data)
                }
                setListTypes(arr);
                setListTypesOrigin(arr)
                setSp(false);
                
            }else if(types === 'columnas'){
                // let arr=[]
                for(let i in res.data.data){
                    for(let j in res.data.data[i].columnas){
                        for(let k in res.data.data[i].columnas[j].canastas){
                            for(let m in res.data.data[i].columnas[j].canastas[k]){
                                res.data.data[i].columnas[j].canastas[k].status= false
                            }
                        }
                    }
                   
                }
                setListTypes(res.data.data);
                setListTypesOrigin(res.data.data)
                setSp(false);
            }else{
                let data=[]
                for(let i in res.data.data){
                    for(let j in res.data.data[i].referencias){
                        
                            res.data.data[i].referencias[j].status=false
                            data.push(res.data.data[i].referencias[j])
                        }
                    }
                   setListTypes(res.data.data);
                   setListTypesOrigin(res.data.data)
                setSp(false);
                setDataRef(data)
            }
        } catch (error) {
            Swal.fire({
                title: '',
                icon: 'error',
                html: (error.response.data.message? error.response.data.message : 'Hubo un error contacte al administrador' ),
                focusConfirm: false,
                confirmButtonText:'Aceptar',
            })

            setSp(false)

        }
    }
     
     const handleChangeType =(e)=>{
        setListTypes([])
        setListTypesOrigin([])
        setCheckedList([])
        setType(e)
        getListTypes(e)
        setBuscar('')
        setShowSearchs(true)
        setAllChecked(false)
     }
    
    const onCheckAllChange = (e) => {
        // activateSP(true)
        setAllChecked(e.target.checked)
        
        // setCheckedList(e.target.checked ? 
        if(e.target.checked){
            setCheckedList(listTypes)
            if(type==='estanterias'){
                let dataAll=[]
                for(let i in listTypes){
                    listTypes[i].status=true
                    setCheckedList([...checkedList, listTypes[i].item])
                    dataAll.push(listTypes[i].item)
                }
                setCheckedList(dataAll)
            }else if(type === 'columnas'){
               
                for(let i in listTypes){
                    for(let j in listTypes[i].columnas){
                        for(let k in listTypes[i].columnas[j].canastas){
                            listTypes[i].columnas[j].canastas[k].status=true
                        }
                    }
                }
                const checkboxes = document.querySelectorAll('input[id="all"]');
                checkboxes.forEach(checkbox => {
                    checkbox.checked = true;
                });
            }else{
                
                for(let i in listTypes){
                    for(let j in listTypes[i].referencias){
                        listTypes[i].referencias[j].status=true
                    }

                }
                setCheckedList(listTypes)
                const checkboxes = document.querySelectorAll('input[id="all"]');
                    
                    checkboxes.forEach(checkbox => {
                        checkbox.checked = true;
                    });
                    let allInfo=[]
                    for(let i in listTypes){
                        
                        let res= listTypes[i].referencias.filter(d=> {!d.status && allInfo.push(d)})
                    }
                    
                    if(allInfo.length=== 0 ){
                        setAllChecked(true)
                    }else{
                        setAllChecked(false)
                    }
            }
            
        }else{
            if(type==='estanterias'){
                for(let i in listTypes){
                    listTypes[i].status=false
                }
            }else if(type=== 'columnas'){
                for(let i in listTypes){
                    for(let j in listTypes[i].columnas){
                        for(let k in listTypes[i].columnas[j].canastas){
                            listTypes[i].columnas[j].canastas[k].status=false
                        }
                    }
                }
                const checkboxes = document.querySelectorAll('input[id="all"]');
                
                checkboxes.forEach(checkbox => {
                    checkbox.checked = false;
                });
            }else{
                for(let i in listTypes){
                    for(let j in listTypes[i].referencias){
                        listTypes[i].referencias[j].status=false
                    }
                }
                // SEARCH CHECKBOX ALL
                const checkboxes = document.querySelectorAll('input[id="all"]');
                
                checkboxes.forEach(checkbox => {
                    checkbox.checked = false;
                });

                let allInfo=[]
                for(let i in listTypes){
                    listTypes[i].referencias.filter(d=> {!d.status && allInfo.push(d)})
                }
                if(allInfo.length=== 0 ){
                    setAllChecked(true)
                }else{
                    setAllChecked(false)
                }
                
            }
            setCheckedList([])
        }
           
       
        
        if(type === 'columnas' && e.target.checked){
            checkedList=listTypes
            
            isChecked(listTypes, 'allPal')
        }else{
            checkedList=listTypes
            for(let i in listTypes){
                isChecked(listTypes[i], 'allPal')
            }
            
        }
        
    };

    const handleCheckboxChange = (item, isAll, pos, checked) => {
    
        if(isAll){
            if(type === 'estanterias'){
                const newCheckedList = checkedList.includes(item.item)
                ? checkedList.filter(i => i !== item.item)
                : [...checkedList, item.item];            
                setCheckedList(newCheckedList)
            }else if(type === 'columnas'){
               
                let newCheckedList = ''
                let dataNormalized=false
                if(allChecked){
                    
                    let info= ''
                    let a=[]
                    for(let j in checkedList){
                        if(checkedList[j].columnas){
                            info= checkedList[j].columnas.filter(k=> k.cod_columna===item.cod_columna)
                            a.push(info)
                        }else{
                            dataNormalized=true
                        }
                    }
                    if(dataNormalized){
                        
                        info= checkedList.filter(k=> k.cod_columna===item.cod_columna) 
                        a.push(info) 
                    }
                    newCheckedList= a
                }else{
                    
                    newCheckedList= checkedList.filter(i=> i.cod_columna===item.cod_columna)
                }
                
                if(newCheckedList.length>0 ){
                    
                    if(checked){
                        
                        for(let i=0; i<listTypes.length; i++){
                            for(let j=0; j<listTypes[i].columnas.length; j++){
                                if(listTypes[i].columnas[j].cod_columna === item.cod_columna){
                                    
                                    for(let k =0; k<listTypes[i].columnas[j].canastas.length;k++){
                                        
                                        listTypes[i].columnas[j].canastas[k].status=true
                                    }
                                }
                            }
                        }
                        let totalSelected=0
                        
                        for(let i in newCheckedList){
                            if(newCheckedList[i].cod_columna === item.cod_columna){
                                totalSelected=totalSelected+1
                            }
                        }
                        
                        if(totalSelected === item.canastas.length){
                            setAllChecked(true)
                        }else{
                            setAllChecked(false)
                        }
                         newCheckedList=checkedList.filter(i => i.cod_columna!== item.cod_columna)
                        setCheckedList(newCheckedList)
                    }else{
                        for(let i=0; i<listTypes.length; i++){
                            for(let j=0; j<listTypes[i].columnas.length; j++){
                                if(listTypes[i].columnas[j].cod_columna === item.cod_columna){
                                    
                                    for(let k =0; k<listTypes[i].columnas[j].canastas.length;k++){
                                        
                                        listTypes[i].columnas[j].canastas[k].status=false
                                    }
                                }
                            }
                        }
                         newCheckedList=checkedList.filter(i => i.cod_columna!== item.cod_columna)
                        setCheckedList(newCheckedList)
                        if(isAll){
                            
                            setAllChecked(false)
                            const miCheckbox = document.getElementsByName(`${item.cod_columna}-all`);
                           
                            miCheckbox[0].checked = false
                            let data=[]
                            for(let i in newCheckedList){
                                if(newCheckedList[i].columnas){
                                    for(let j in newCheckedList[i].columnas){
                                        for(let k in newCheckedList[i].columnas[j].canastas){
                                            if(newCheckedList[i].columnas[j].canastas[k].status){
                                                
                                                let d={
                                                    cod_columna:newCheckedList[i].columnas[j].cod_columna,
                                                    canasta:newCheckedList[i].columnas[j].canastas[k].canasta,
                                                    cantidad:newCheckedList[i].columnas[j].canastas[k].cantidad,
                                                    status: newCheckedList[i].columnas[j].canastas[k].status
                                                }
                                                data.push(d)
                                            }
                                            
                                        }
                                       
                                    }
                                }else{
                                    if(newCheckedList[i].status){
                                        
                                        let d={
                                            cod_columna:newCheckedList[i].cod_columna,
                                            canasta:newCheckedList[i].canasta,
                                            cantidad:newCheckedList[i].cantidad,
                                            status: newCheckedList[i].status
                                        }
                                        data.push(d)
                                    }
                                } 
                            }
                            
                            newCheckedList=data
                            setCheckedList(newCheckedList)
                        }
                    }
                    setSp(false)
                    
                }else{
                    
                    for(let i=0; i<listTypes.length; i++){
                        for(let j=0; j<listTypes[i].columnas.length; j++){
                            if(listTypes[i].columnas[j].cod_columna === item.cod_columna){
                                
                                for(let k =0; k<listTypes[i].columnas[j].canastas.length;k++){
                                    
                                    listTypes[i].columnas[j].canastas[k].status=true
                                }
                            }
                        }
                    }
                    
                    let totalSelected=0
                    let allInfo=[]
                        for(let i in listTypesOrigin){
                            for(let j in listTypesOrigin[i].columnas){
                                listTypesOrigin[i].columnas[j].canastas.filter(d=> {!d.status && allInfo.push(d)})
                            }
                        }
                        
                        if(allInfo.length=== 0 ){
                            setAllChecked(true)
                        }else{
                            setAllChecked(false)
                        }
                    for(let i in newCheckedList){
                        if(newCheckedList[i].cod_columna === item.cod_columna){
                            totalSelected=totalSelected+1
                            
                        }
                    }
                    // if(totalSelected === item.canastas.length){
                    //     setAllChecked(true)
                    // }else{
                    //     setAllChecked(false)
                    // }
                    let can=[]
                
                    for(let i=0; i<item.canastas.length; i++){
                        let d={
                            cod_columna: item.cod_columna,
                            canasta: item.canastas[i].canasta,
                            cantidad: item.canastas[i].cantidad,
                            status: item.canastas[i].status,
                        }
                        can.push(d)
                    }
                    newCheckedList=can
                    
                    setCheckedList(prev => [
                        ...prev,
                        ...can
                    ])
                }
            }else{
                let newCheckedList = checkedList.filter(i=> i.cod_ubicacion===item.cod_ubicacion)
                
                if(newCheckedList.length>0 ){
                    
                    //cheched true
                    if(checked){
                        let arr=[]
                        for(let i=0; i<listTypes.length; i++){
                            if(listTypes[i].cod_ubicacion=== item.cod_ubicacion){
                                for(let j=0; j<listTypes[i].referencias.length; j++){
                                    listTypes[i].referencias[j].status=true
                                    for(let k in newCheckedList){
                                        if(newCheckedList[k].referencia !== listTypes[i].referencias[j].referencia){
                                            let d={
                                                cod_ubicacion:item.cod_ubicacion,
                                                referencia:listTypes[i].referencias[j].referencia,
                                                cantidad:listTypes[i].referencias[j].cantidad,
                                                status:listTypes[i].referencias[j].status,
                                            }
                                            arr.push(d)
                                        }
                                    }
                                }
                            }
                        }
                        
                        newCheckedList= arr                        
                        setCheckedList([...checkedList, ...newCheckedList]) 
                        let allInfo=[]
                        for(let i in listTypes){
                            listTypes[i].referencias.filter(d=> {!d.status && allInfo.push(d)})
                        }
                        
                        if(allInfo.length=== 0 ){
                            setAllChecked(true)
                        }else{
                            setAllChecked(false)
                        }
                    }else{
                        
                        for(let i=0; i<listTypes.length; i++){
                            if(listTypes[i].cod_ubicacion=== item.cod_ubicacion){
                                for(let j=0; j<listTypes[i].referencias.length; j++){
                                    listTypes[i].referencias[j].status=false
                                }
                            }
                        }
                        let normalizedData=false
                        if(allChecked){
                            const miCheckbox = document.getElementsByName(`${item.cod_ubicacion}-all`);
                            miCheckbox[0].checked = false
                            setAllChecked(false)
                            normalizedData=true
                        }
                        newCheckedList=checkedList.filter(i => i.cod_ubicacion!== item.cod_ubicacion)
                        let newData= []
                        if(normalizedData){
                            for(let i in newCheckedList){
                                for(let j in newCheckedList[i].referencias){
                                    let d={
                                        cod_ubicacion:newCheckedList[i].cod_ubicacion,
                                        referencia:newCheckedList[i].referencias[j].referencia,
                                        cantidad: newCheckedList[i].referencias[j].cantidad,
                                        status:newCheckedList[i].referencias[j].status
                                    }
                                    newData.push(d)
                                }
                            }
                        }
                        setCheckedList(newData) 
                        //verifing that all are selected
                        let allInfo=[]
                        for(let i in listTypes){
                            listTypes[i].referencias.filter(d=> {!d.status && allInfo.push(d)})
                        }
                        
                        if(allInfo.length=== 0 ){
                            setAllChecked(true)
                        }else{
                            setAllChecked(false)
                        }
                    }
                }else{
                    if(checked){
                        let arr=[]
                        for(let i=0; i<listTypes.length; i++){
                            if(listTypes[i].cod_ubicacion=== item.cod_ubicacion){
                                for(let j=0; j<listTypes[i].referencias.length; j++){
                                    listTypes[i].referencias[j].status=true
                                    let d={
                                        cod_ubicacion:item.cod_ubicacion,
                                        referencia:listTypes[i].referencias[j].referencia,
                                        cantidad:listTypes[i].referencias[j].cantidad
                                    }
                                    arr.push(d)
                                }
                            }
                        }
                        setCheckedList([...checkedList, ...arr])
                        //verifing that all are selected
                        let allInfo=[]
                        for(let i in listTypes){
                            let res= listTypes[i].referencias.filter(d=> {!d.status && allInfo.push(d)})
                        }
                        
                        if(allInfo.length=== 0 ){
                            setAllChecked(true)
                        }else{
                            setAllChecked(false)
                        }
                    }else{
                        for(let i=0; i<listTypes.length; i++){
                            if(listTypes[i].cod_ubicacion=== item.cod_ubicacion){
                                for(let j=0; j<listTypes[i].referencias.length; j++){
                                    listTypes[i].referencias[j].status=false
                                }
                            }
                        }
                        
                        newCheckedList=checkedList.filter(i => i.cod_ubicacion!== item.cod_ubicacion)
                        setCheckedList(newCheckedList)
                        //verifing that all are selected
                        let allInfo=[]
                        for(let i in listTypes){
                            let res= listTypes[i].referencias.filter(d=> {!d.status && allInfo.push(d)})
                        }
                        
                        if(allInfo.length=== 0 ){
                            setAllChecked(true)
                        }else{
                            setAllChecked(false)
                        }
                    }
                    
                }

            }
           setSp(false)
        }else{
           if(type === 'estanterias'){
                let newCheckedList = checkedList.filter(i=>(i===item.item))
                if(newCheckedList.length>0){
                    newCheckedList=checkedList.filter(i => i!== item.item)
                    listTypes[pos].status= false
                }else{
                    listTypes[pos].status= true                
                    let d=item.item
                    newCheckedList=[...checkedList,d]
                }
                if(newCheckedList.length < listTypes.length){
                    setAllChecked(false)
                }else if(newCheckedList.length === listTypes.length){
                    setAllChecked(true)
                }
                setCheckedList(newCheckedList)
                checkedList=newCheckedList
           }else if(type === 'columnas'){
                let newCheckedList = '' 
                let normalizedData=false
                if(allChecked){
                    for(let i in checkedList){
                        if(checkedList[i].columnas){
                            for(let j in checkedList[i].columnas){
                                if(item.cod_columna === checkedList[i].columnas[j].cod_columna){
                                    
                                    if(checkedList[i].columnas[j].cod_columna === item.cod_columna){
                                        newCheckedList = checkedList[i].columnas[j].canastas.filter(i=>( i.canasta === item.canastas[pos].canasta))
                                    }
                                }
                            }
                        }else{
                            newCheckedList = checkedList.filter(i=>((i.cod_columna=== item.cod_columna) && (i.canasta === item.canastas[pos].canasta)))
                        }
                        
                    }
                   
                    normalizedData= true
                    setAllChecked(false)
                }else{
                    newCheckedList = checkedList.filter(i=>((i.cod_columna===item.cod_columna) &&( i.canasta === item.canastas[pos].canasta)))
                }
                setCheckedList(newCheckedList)  
               
                if(newCheckedList.length>0){
                    
                    newCheckedList=checkedList.filter(i =>(i.canasta !==item.canastas[pos].canasta))
                  
                    setCheckedList(newCheckedList)  
                    let data={}
                    for(let i=0; i<listTypes.length; i++){
                        for(let j=0; j<listTypes[i].columnas.length;j++){
                            if(listTypes[i].columnas[j].cod_columna === item.cod_columna){
                                data=listTypes[i].columnas[j]
                            }
                        }
                    }
                    
                    if(checked){
                        data.canastas[pos].status=true
                    }else{
                        data.canastas[pos].status=false
                    }
                    
                    
                    
                    if(normalizedData){
                      
                        let newData=[]
                        for(let i=0; i<listTypes.length; i++){
                            if(listTypes[i].columnas){
                                for(let j=0; j<listTypes[i].columnas.length;j++){
                                    for(let k=0; k<listTypes[i].columnas[j].canastas.length; k++){
                                         if(listTypes[i].columnas[j].canastas[k].status){
                                             let d={
                                                 cod_columna: listTypes[i].columnas[j].cod_columna,
                                                 canasta:listTypes[i].columnas[j].canastas[k].canasta,
                                                 cantidad:listTypes[i].columnas[j].canastas[k].cantidad,
                                                 status:listTypes[i].columnas[j].canastas[k].status
                                             }
                                             newData.push(d)
                                         }
                                    }
                                 }
                            }else{
                               
                                if(listTypes[i].status){
                                    let d={
                                        cod_columna: listTypes[i].cod_columna,
                                        canasta:listTypes[i].canasta,
                                        cantidad:listTypes[i].cantidad,
                                        status:listTypes[i].status
                                    }
                                    newData.push(d)
                                }
                            }
                            
                        }
                        
                        setCheckedList(newData)
                        normalizedData=false
                    }else{
                        
                        let isTrue=[]
                        for(let i in listTypes){
                            for(let j in listTypes[i].columnas){
                                listTypes[i].columnas[j].canastas.filter(k=>k.status && isTrue.push(
                                    {cod_columna: listTypes[i].columnas[j].cod_columna,
                                    canasta:k.canasta,
                                    cantidad:k.cantidad,
                                    status:k.status}))
                               
                            }
                        }
                        newCheckedList=isTrue
                       
                       setCheckedList(newCheckedList)
                    }

                }else{
                    
                    let data={}
                    for(let i=0; i<listTypes.length; i++){
                        if(listTypes[i].columnas){
                            for(let j=0; j<listTypes[i].columnas.length;j++){
                                if(listTypes[i].columnas[j].cod_columna === item.cod_columna){
                                    data=listTypes[i].columnas[j]
                                }
                            }
                        }else{
                            if(listTypes[i].cod_columna === item.cod_columna){
                                data=listTypes[i]
                            }
                        }
                        
                    }
                           
                    if(data.length>0){
                        data.canastas[pos].status=false
                        let d={
                            cod_columna: item.cod_columna,
                            canasta: item.canastas[pos].canasta,
                            cantidad: item.canastas[pos].cantidad,
                            status: item.canastas[pos].status,
                        }
                        newCheckedList=[...checkedList, d]
                    }else{
                        data.canastas[pos].status=true
                        let d={
                            cod_columna: item.cod_columna,
                            canasta: item.canastas[pos].canasta,
                            cantidad: item.canastas[pos].cantidad,
                            status: item.canastas[pos].status,
                        }
                        newCheckedList=[...checkedList, d]
                    }
                    
                          setCheckedList(newCheckedList)     
                }
                
                    let totalSelected=0
                    
                    for(let i in newCheckedList){
                        if(newCheckedList[i].cod_columna === item.cod_columna){
                            totalSelected=totalSelected+1
                            
                        }
                    }
                    
                    if(totalSelected === item.canastas.length){
                        const miCheckbox = document.getElementsByName(`${item.cod_columna}-all`);
                        miCheckbox[0].checked = true
                    }else{
                        const miCheckbox = document.getElementsByName(`${item.cod_columna}-all`);
                        miCheckbox[0].checked = false
                    }
                    let allInfo=[]
                    for(let i in listTypes){  
                        for(let j in listTypes[i].columnas){
                            listTypes[i].columnas[j].canastas.filter(d=> {!d.status && allInfo.push(d)})
                        }                     
                    }
                    
                    if(allInfo.length===0){
                        setAllChecked(true)
                    }else{
                        setAllChecked(false)
                    }
                    
           }else{
                //CANASTAS            
                let actualLocation=item.cod_ubicacion
                let newCheckedList = '' 
                let normalizedData=false
                if(allChecked ){
                    let a = checkedList.filter(d=>d.referencias)
                   if(a.length>0){
                    
                    newCheckedList=  checkedList.filter(i=>((i.cod_ubicacion === actualLocation)&& (i.referencias[pos].referencia === item.referencias[pos].referencia)))
                    normalizedData= true
                    setAllChecked(false)
                   }else{
                        newCheckedList=  checkedList.filter(i=>(((i.cod_ubicacion === actualLocation)|| (i.cod_ubicacion !== actualLocation))&& (i.referencia !== item.referencias[pos].referencia)))
                   }
                   
                }else{
                    
                    newCheckedList=  checkedList.filter(i=>((i.cod_ubicacion === actualLocation)&& (i.referencia === item.referencias[pos].referencia)))
                }
                
                if(newCheckedList.length>0){
                    
                    newCheckedList=checkedList.filter(i =>(((i.referencia !== item.referencias[pos].referencia) && (i.cod_ubicacion !== item.cod_ubicacion))|| ((i.referencia === item.referencias[pos].referencia) && (i.cod_ubicacion !== item.cod_ubicacion))))
                    
                    for(let i=0; i<listTypes.length; i++){
                        for(let j=0; j<listTypes[i].referencias.length; j++){
                            if((listTypes[i].referencias[j].referencia === item.referencias[pos].referencia) && (listTypes[i].cod_ubicacion === item.cod_ubicacion)){
                                listTypes[i].referencias[j].status=false
                            }
                        }
                    }
                    if(normalizedData){
                        let dataTotal=[]
                        let d=''
                        for(let i in checkedList){
                            for(let j in checkedList[i].referencias){
                                
                                if(checkedList[i].referencias[j].status){
                                    d={
                                        cod_ubicacion:checkedList[i].cod_ubicacion,
                                        referencia:checkedList[i].referencias[j].referencia,
                                        cantidad:checkedList[i].referencias[j].cantidad,
                                        status: checkedList[i].referencias[j].status
                                    }
                                }
                                
                               
                            }
                            if(checkedList[i].referencias[pos].status){
                                dataTotal.push(d)
                            }
                            
                        }
                        
                        newCheckedList=dataTotal
                    }

                    let totalSelected=0
                    
                    for(let i in newCheckedList){
                        if(newCheckedList[i].cod_ubicacion === item.cod_ubicacion){
                            totalSelected=totalSelected+1
                            
                        }                        
                    }
                   
                    let cantTotal = 0
                    let nameCanasta=''
                    for(let i in listTypes){
                        for(let j in listTypes[i].referencias){
                            if(listTypes[i].referencias[j].referencia === item.referencias[pos].referencia){
                                cantTotal= item.referencias.length
                                nameCanasta=listTypes[i].cod_ubicacion
                            }

                        }
                    }
                    
                    let allInfo=[]
                    for(let i in listTypes){                       
                        listTypes[i].referencias.filter(d=> {!d.status && allInfo.push(d)})
                        if(allInfo.length===0){
                            setAllChecked(true)
                        }else{
                            setAllChecked(false)
                        }
                        
                    }
                    if(totalSelected === cantTotal){
                        const miCheckbox = document.getElementsByName(`${item.cod_ubicacion}-all`);
                        miCheckbox[0].checked = true
                    }else{
                        const miCheckbox = document.getElementsByName(`${item.cod_ubicacion}-all`);
                        miCheckbox[0].checked = false
                    }
                    setCheckedList(newCheckedList)

                }else{
                    
                    let arr=[]
                    
                    for(let i=0; i<listTypes.length; i++){
                        
                            if((listTypes[i].cod_ubicacion === actualLocation)){
                                
                                listTypes[i].referencias[pos].status=true
                                let d = {
                                    cod_ubicacion: listTypes[i].cod_ubicacion,
                                    referencia:listTypes[i].referencias[pos].referencia,
                                    cantidad: listTypes[i].referencias[pos].cantidad,
                                    status: listTypes[i].referencias[pos].status
                                }
                                arr.push(d)
                            }
                        
                        
                        newCheckedList= [...checkedList, ...arr]
                        setCheckedList(newCheckedList)
                    }
                    let totalSelected=0
                    
                    for(let i in newCheckedList){
                        if(newCheckedList[i].cod_ubicacion === item.cod_ubicacion){
                            totalSelected=totalSelected+1
                            
                        }                        
                    }
                   
                    let cantTotal = 0
                    let nameCanasta=''
                    for(let i in listTypes){
                        for(let j in listTypes[i].referencias){
                            if(listTypes[i].referencias[j].referencia === item.referencias[pos].referencia){
                                
                                cantTotal= item.referencias.length
                                nameCanasta=listTypes[i].cod_ubicacion
                            }
                        }
                    }
                    
                    let allInfo=[]
                    for(let i in listTypes){
                        
                        listTypes[i].referencias.filter(d=> {!d.status && allInfo.push(d)})
                    }
                    if(allInfo.length=== 0 ){
                        setAllChecked(true)
                    }else{
                        setAllChecked(false)
                    }
                    if(totalSelected === cantTotal){
                        
                        const miCheckbox = document.getElementsByName(`${item.cod_ubicacion}-all`);
                        
                        miCheckbox[0].checked = true
                    }else{
                        
                        const miCheckbox = document.getElementsByName(`${item.cod_ubicacion}-all`);
                        
                        miCheckbox[0].checked = false
                    }
                }
           }
            
        }
        setSp(false)
        
    };
    
    const isChecked = (item, all) => {
        
        if(type== 'columnas'){
            if(allChecked ){
                
                let arr=[]
                for(let i=0; i<checkedList.length; i++){
                    for(let j=0; j<checkedList[i].columnas.length; j++){
                        for(let k=0; k<checkedList[i].columnas[j].canastas.length; k++){
                            arr.push(checkedList[i].columnas[j].canastas[k].canasta)
                        }
                    }
                }
               
                return arr.includes(item);
                
            }
            if(all === 'all'){
                
                let arr=[]
                for(let i=0; i<checkedList.length; i++){
                    
                    for(let j=0; j<checkedList[i].canastas.length; j++){
                        
                            arr.push(checkedList[i].canastas[j].canasta)
                        
                    }
                }
               
                return arr.includes(item.canasta);
                
            }else{
                
                return checkedList.includes(item); 
            }
        }else{
            return checkedList.includes(item)
        }
        
    };
    const handlechangeSearch =(e)=>{
       
        setBuscar(e.target.value)
        if(e.target.value !== ''){
            clearTimeout(time) 
            setTime(setTimeout(() => 
            {   
                searchInfo(e.target.value)           
            }, 800))  
        }else{
            setListTypes(listTypesOrigin)
        }
        
    }
    const searchInfo=async(value)=>{
        try {
           
            if(type === 'estanterias'){
                setSp(true)
                let res= listTypesOrigin.filter(elemento => elemento.item.includes( `${value}`))
                if(res.length>0){
                    setListTypes(res);
                }else{
                    setListTypes([])
                }
                setSp(false);
            }
            else if(type === 'columnas'){
                setSp(true)
                
                
                if(value.length<=4){
                    let arr=[]
                    for(let i in listTypesOrigin){
                        for(let j in listTypesOrigin[i].columnas){
                            if(listTypesOrigin[i].columnas[j].cod_columna.includes( `${value}`)){
                                arr.push({columnas:[listTypesOrigin[i].columnas[j]]})
                            }
                       
                        }
                    }
                    setListTypes(arr);                    
                }
                if(value.length>4){
                    let arr=[]
                    let arr1=[]
                    for(let i in listTypesOrigin){
                        for(let j in listTypesOrigin[i].columnas){
                            for(let k in listTypesOrigin[i].columnas[j].canastas){
                                if(listTypesOrigin[i].columnas[j].canastas[k].canasta.includes( `${value}`)){
                                    
                                   if(!arr1.includes( `${listTypesOrigin[i].columnas[j].cod_columna}`)){
                                        arr1.push(listTypesOrigin[i].columnas[j])
                                   }
                                    
                                }    
                            }
                        }
                    }
                    
                    const myUniqueArray = [...new Set(arr1)]
                    arr.push({columnas:myUniqueArray})
                    setListTypes(arr); 
                }
                
                // setListTypesOrigin(res.data.data)
                setSp(false);
            }else{
                let arr=[]
                if(value.length<=6){
                    for(let i in listTypesOrigin){
                                if(listTypesOrigin[i].cod_ubicacion.includes( `${value}`)){
                                    arr.push(listTypesOrigin[i])
                                }else{
                                    for(let j in listTypesOrigin[i].referencias){
                                        if(listTypesOrigin[i].referencias[j].referencia.includes( `${value}`)){
                                            arr.push(listTypesOrigin[i])
                                        }
                                    }
                                } 
                            }
                        
                    setListTypes(arr); 
                }
                // let data=[]
                // for(let i in res.data.data){
                //     for(let j in res.data.data[i].referencias){
                        
                //             res.data.data[i].referencias[j].status=false
                //             data.push(res.data.data[i].referencias[j])
                //         }
                //     }
                //    setListTypes(res.data.data);
                //    setListTypesOrigin(res.data.data)
                // setSp(false);
                // setDataRef(data)
            }
        } catch (error) {
            setSp(false)
        }
    }
    const deleteLocation= async()=>{
        const dynamicKey = type==='estanterias' ? 'e' : (type=== 'canastas'?'can':  'col')
        let dataEnd={}
        if(type=== 'columnas'){
            let arr=[]
            if(allChecked){
                let arrCanasta=[]
                const groupedData = {};
                for(const i in checkedList){
                    if(checkedList[i].columnas){
                        
                        for(const j in checkedList[i].columnas){
                            
                            for(const k in checkedList[i].columnas[j].canastas){
                                
                                arrCanasta.push(checkedList[i].columnas[j].canastas[k].canasta)
    
                            }
                            arr.push({columnas:checkedList[i].columnas[j].cod_columna, canastas:arrCanasta})
                        }
                        
                    }else{
                     
                        if (!groupedData[checkedList[i].cod_columna]) {
                            groupedData[checkedList[i].cod_columna] = { columnas: checkedList[i].cod_columna, canastas: [] };
                        }
                            groupedData[checkedList[i].cod_columna].canastas.push(checkedList[i].canasta);                       
                    }
                    
                }
               if(arr.length>0){
                    dataEnd= arr 
               }else{
                    dataEnd=Object.values(groupedData)
               }
                               
                
            }else{

                const groupedData = {};
                checkedList.forEach(item => {
                    const { cod_columna, canasta } = item;
                    if (!groupedData[cod_columna]) {
                        groupedData[cod_columna] = { columnas: cod_columna, canastas: [] };
                    }
                        groupedData[cod_columna].canastas.push(canasta);
                });
                
                dataEnd=Object.values(groupedData)
                
            }
        }else if(type === 'canastas'){
            const groupedData = {};
            if(allChecked){
                
                const arrayModificado = checkedList.map(i => {
                    const nuevoObjeto = { ...i }; 
                    nuevoObjeto.canastas = nuevoObjeto.cod_ubicacion;
                    delete nuevoObjeto.cod_ubicacion;
                    
                    return nuevoObjeto
                  });
                  
                  arrayModificado.forEach(item => {
                    const { canastas, referencias } = item;
                    
                    if (!groupedData[canastas]) {
                        groupedData[canastas] = { canastas: canastas, referencias: [] };
                    }
                    if(item.referencias){
                        for(let j in item.referencias){
                            groupedData[canastas].referencias.push(item.referencias[j].referencia);
                        }
                    }else{
                        groupedData[canastas].referencias.push(item.referencia);
                    }
                        
                        
                });
                
                dataEnd= Object.values(groupedData)
            }else{
                
                checkedList.forEach(item => {
                    const { cod_ubicacion, referencia } = item;
                    if (!groupedData[cod_ubicacion]) {
                        groupedData[cod_ubicacion] = { canastas: cod_ubicacion, referencias: [] };
                    }
                    
                        groupedData[cod_ubicacion].referencias.push(referencia);
                });
                
                dataEnd=Object.values(groupedData) 
            }
            
        
                   
        }else{
            dataEnd={
                tipo: {
                [dynamicKey]: checkedList
              }
            }
        }
        
        Swal.fire({
            title: "",
            text: `¿Desea eliminar la información seleccionada?`,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#23b991",
            cancelButtonColor: "#d33",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar"
            }).then(async(result) => {
                if (result.isConfirmed) {
                    try {
                        setSp(true)
                        const res= await Call('POST', `/api/bodegas/eliminar/${type}/${listBodegas.length>1?selectedBodegaId: listBodegas[0].id}`, true,dataEnd, false)
                        let arr=[]

                        if(type === 'estanterias'){
                            for(let i in res.data.data){
                                let data={
                                    item:res.data.data[i],
                                    status: false
                                }
                                arr.push(data)
                            }
                            setListTypes(arr);
                        }else if(type === 'columnas'){
                            
                            for(let i in res.data.data){
                                for(let j in res.data.data[i].columnas){
                                    for(let k in res.data.data[i].columnas[j].canastas){
                                        for(let m in res.data.data[i].columnas[j].canastas[k]){
                                            res.data.data[i].columnas[j].canastas[k].status= false
                                        }
                                    }
                                }
                            
                            }
                            setListTypes(res.data.data)
                            setListTypesOrigin(res.data.data)
                        }else{
                            
                            let data=[]
                            for(let i in res.data.data){
                                for(let j in res.data.data[i].referencias){
                                    
                                        res.data.data[i].referencias[j].status=false
                                        data.push(res.data.data[i].referencias[j])
                                }
                            }
                            setListTypes([]);
                            setListTypes(res.data.data);
                            setListTypesOrigin(res.data.data)
                        }
                        if(checkedList.length === 0){
                            const checkboxes = document.querySelectorAll('input[type="checkbox"]');
                            checkboxes.forEach(checkbox => {
                                checkbox.checked = false;
                            });
                        }
                        
                        
                        setSp(false)
                        setCheckedList([])
                        Swal.fire({
                            title: '',
                            icon: 'success',
                            html: res.data.message,
                            focusConfirm: false,
                            confirmButtonText:'Aceptar',
                        })
                    } catch (error) {
                        setSp(false)
                    }
                }
            })
    }
     

    return(
        <div className='pb-4'>
            { sp ? <Spinner /> : ''}  {/*LOADER*/}
            <div className='ocultar-movil'> 
                <TitleLayout data={datat} clase={"text-left"} />
            </div>
            {
                showModalChange &&
                <>
                <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{background:'transparent'}}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Eliminar unidades</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setDataSelected({}); setNewAmount('');setErrorEmpty(false)}}>
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                            <div className='d-flex flex-wrap justify-content-around align-end'>
                               
                                <div className='number'>
                                     <h6 className='fs-12'>Cantidad a eliminar</h6>
                                    <input 
                                        type='number' 
                                        name="newAmount"
                                        id="newAmount"
                                        value={newAmount}
                                        onChange={(e)=>{setNewAmount(e.target.value)}}
                                        className='form-control form-control-sm'
                                    />
                                    { errorEmpty && <span className='text-danger fs-12'>El campo no puede estar vacío</span>}
                                </div>
                                
                                <div className='cursor-pointer btn-outline-bj height-fit py-1 px-3'
                                    onClick={()=>{setNewAmount(dataSelected.cantidad)}}
                                    >
                                        Eliminar todo
                                </div>
                            </div>
                            <div onClick={ConfirmLocation} className='btn-buscar cursor-pointer'>
                                Confirmar
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    
                    </>

            }
            {/* ----------- BODY INTERFAZ ----------------- */}
            <div className='modal-mercar consultar' data-backdrop-static="true">
                <div className='tabs mb-4 mt-1 d-flex justify-content-start px-2' style={{overflowX : 'auto'}}>
                    <div className={`tab ${activeTab === 'ubicar' ? 'active' : ''}`} onClick={() => handleTabChange('ubicar')}>
                        Ubicar
                    </div>
                    <div className={`tab ${activeTab === 'eliminar' ? 'active' : ''}`} onClick={() => handleTabChange('eliminar')}>
                        Eliminar
                    </div>
                    <div className={`tab ${activeTab === 'transferir' ? 'active' : ''}`} onClick={() => handleTabChange('transferir')}>
                        Transferir
                    </div>
                    <div className={`tab ${activeTab === 'historial' ? 'active' : ''}`} onClick={() => handleTabChange('historial')}>
                        Historial
                    </div>
                    <div className={`tab ${activeTab === 'masivas' ? 'active' : ''}`} onClick={() => handleTabChange('masivas')}>
                        Ubicaciones Masivas
                    </div>
                </div>
                {   
                    activeTab === 'ubicar' && (
                        <div className='px-4'>
                            <div className='mb-4 mt-1'>
                                <Link to="/bodega/ubicacion-inventario" className='btn-buscar fw-400'>
                                    <i className="fas fa-thumbtack"></i> Ubicar
                                </Link>
                            </div>

                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-sm-12 my-2'>
                                    <div className='label-decoracion'>
                                        <span>Bodega</span>
                                    </div>
                                    <Select
                                        className='w-100'
                                        showSearch
                                        placeholder="Selecciona una bodega"
                                        optionFilterProp="children"
                                        onChange={(option, children) => handleChange(option, children)}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {listBodegas &&
                                            listBodegas.map((lb, indexlb) => (
                                                <Option name="bodega" key={indexlb + lb.codigo} value={lb.codigo}>
                                                    {lb.nombre}
                                                </Option>
                                            ))}
                                    </Select>
                                </div>
                                <div className='col-lg-12 col-md-12 col-sm-12 my-2'>
                                    <div className='label-decoracion'>
                                        <span>Leer código</span>
                                    </div>
                                    {consultar.bodega !== '' ? (
                                        <input
                                            type="text"
                                            className='form-control form-control-sm form-color form-colord'
                                            id="codigo"
                                            name="codigo"
                                            value={consultar.codigo}
                                            placeholder="Leer código"
                                            onChange={(e) => handleChange(e)}
                                            onKeyUp={(e) => (e.keyCode === 13 ? consultarUbicaciones(e) : '')}
                                        />
                                    ) : (
                                        <input
                                            type="text"
                                            className='form-control form-control-sm form-color'
                                            placeholder="Leer código"
                                            disabled
                                        />
                                    )}
                                </div>
                                <div className='col-lg-2 col-md-2 col-sm-12 my-2 mx-auto'>
                                    {consultar.bodega !== '' && consultar.codigo !== '' ? (
                                        <div className='text-center cursor-pointer btn-buscar' onClick={consultarUbicaciones}>
                                            <i className="fas fa-search fs-18"></i>
                                        </div>
                                    ) : (
                                        <div className='text-center cursor-nallowed btn-buscar'>
                                            <i className="fas fa-search fs-18"></i>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <hr />

                            {busqueda && (
                                <DataTableComp 
                                    columns={columns && columnsPpal && columnsPpal} 
                                    data={dataTable && dataTable} 
                                />
                            )}
                        </div>
                    )
                }

                {
                    activeTab === 'eliminar' && (
                        <div className='px-4'>
                                {   listBodegas&& listBodegas.length === 0 ?
                                        <div className="ticket-empty-modal pb-4 mb-4 pt-3">
                                            <div><i className="fas fa-box-open fs-20"></i></div>
                                            <div className="text-empty">En este momento no tiene una bodega asignada</div>
                                        </div>
                                    :
                                        <>
                                            {    
                                                listBodegas&& listBodegas.length > 1 ?
                                                    <Select
                                                        className='w-100 mb-0'
                                                        showSearch
                                                        placeholder="Selecciona una bodega"
                                                        optionFilterProp="children"
                                                        onChange={(option, children) => handleChange(option, children)}
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().includes(input.toLowerCase())
                                                        }
                                                    >
                                                        {listBodegas &&
                                                            listBodegas.map((lb, indexlb) => (
                                                                <Option name="bodega" key={indexlb + lb.codigo} value={lb.id}>
                                                                    {lb.nombre}
                                                                </Option>
                                                            ))}
                                                    </Select>
                                                :

                                                <span className='text-capitalize fw-600 text-label-span'>
                                                    Bodega: {listBodegas&& listBodegas[0]&& listBodegas[0].nombre} 
                                                </span>
                                            }
                                            { consultar&& ((listBodegas.length>1 && consultar.bodega !=='') || listBodegas.length===1)&& 
                                                <div className='my-3'>
                                                    <Select
                                                        placeholder="Tipo"
                                                        // defaultValue="estanterias"
                                                        style={{ width: '100%' }}
                                                        onChange={handleChangeType}
                                                        value={type}
                                                        options={[
                                                            { value: 'estanterias', label: 'Columnas' },
                                                            { value: 'columnas', label: 'Filas' },
                                                            { value: 'canastas', label: 'Canastas' },
                                                        ]}
                                                    />
                                                </div>
                                            }
                                            
                                            {showSearchs && (
                                            <div>
                                                
                                                <input 
                                                    type="text" 
                                                    className='form-control form-control-sm input-buscar-listform' 
                                                    name="buscarpredist" 
                                                    placeholder="&#xF002;  Buscar" 
                                                    onChange={handlechangeSearch} 
                                                    value={buscar && buscar} 
                                                />
                                            </div>
                                            )}
                                            {/* DATA OPCIONES */}
                                            <section>
                                                { (listTypes&& listTypes.length> 0 && buscar === '') &&
                                                    <div className='mt-3 text-label-span'>
                                                        <div className="form-check form-check-inline d-flex justify-content-between mr-0">
                                                            <label 
                                                                className="form-check-label" 
                                                                htmlFor="inlineCheckbox1"
                                                            >
                                                                    Seleccionar todo
                                                            </label>
                                                            <input 
                                                                className="form-check-input mr-0 big-check" 
                                                                type="checkbox" 
                                                                value="option1"
                                                                onChange={onCheckAllChange}
                                                                checked={allChecked}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                
                                                {
                                                    type === 'estanterias' && (
                                                        <>
                                                        <div className='mt-3 text-label-span'>
                                                            {listTypes&& listTypes.length > 0 ? 
                                                            listTypes.map((item, index) => (
                                                                <div className="mb-3" key={index}>
                                                                    <div className="form-check-inline d-flex justify-content-between mr-0">
                                                                        <label className="form-check-label" htmlFor={`inlineCheckbox${index}`}>
                                                                            Columna: {item.item}
                                                                        </label>
                                                                        <input
                                                                            className="form-check-input mr-0 big-check"
                                                                            type="checkbox"
                                                                            id={`inlineCheckbox${index}`}
                                                                            value={item.item}
                                                                            checked={item.status&& item.status}
                                                                            onChange={() => handleCheckboxChange(item, false, index)}
                                                                        />
                                                                    
                                                                    </div>
                                                                </div>
                                                            ))
                                                            
                                                            :
                                                            <div className="ticket-empty-modal pt-4">
                                                                <div><i className="fas fa-box-open fs-20"></i></div>
                                                                <div className="text-empty">En este momento no hay nada para mostrar</div>
                                                            </div>

                                                        }
                                                        </div>
                                                        </>
                                                    )}

                                                    {type === 'columnas' && (
                                                            <div className='mt-4'>
                                                            <div className="accordion" id="accordionExample">
                                                                { listTypes&& listTypes.length > 0 ? (
                                                                    
                                                                    listTypes.map((column, index2) => (
                                                                        column.columnas&& 
                                                                        column.columnas.map((col, index)=>(
                                                                        
                                                                            <div className="card mb-1 cursor-pointer" key={col.cod_columna}  type="button" 
                                                                               
                                                                            >
                                                                                <div className="p-2 border-bottom" id={`heading${index}`}
                                                                                 data-toggle="collapse" 
                                                                                 data-target={`#collapse${index}`} 
                                                                                 aria-expanded={col.cod_columna === 0 ? 'true' : 'false'} 
                                                                                 aria-controls={`collapse ${col.cod_columna}`}
                                                                                >
                                                                                    <span 
                                                                                        className="fw-600" 
                                                                                    >
                                                                                        Fila: {col.cod_columna}
                                                                                    </span>
                                                                                </div>
                                                                                <div id={`collapse${index}`} className={`collapse ${index === 0 ? 'show' : ''}`} aria-labelledby={`heading${index}`} data-parent="#accordionExample">
                                                                                    <div className="card-body p-1">
                                                                                        {col&& col.canastas && col.canastas.length > 0 ? (
                                                                                            <table className="table" key={index+'n'}>
                                                                                            <thead>
                                                                                                <tr>
                                                                                                <th className='m-2'>Canasta</th>
                                                                                                <th className='m-2'>Cant</th>
                                                                                                <th className='m-2'>
                                                                                                    <input
                                                                                                        key={'n'}
                                                                                                        name={`${col.cod_columna}-all`}
                                                                                                        id="all"
                                                                                                        type="checkbox"
                                                                                                        className='form-control big-check' 
                                                                                                        onChange={(e) => handleCheckboxChange(col, true, null, e.target.checked)}
                                                                                                        checked={null}
                                                                                                    />
                                                                                                </th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {col&& col.canastas&& col.canastas.map((canasta, idx) => (
                                                                                                <tr key={idx}>
                                                                                                    <td>{canasta.canasta}</td>
                                                                                                    <td>{canasta.cantidad}</td>
                                                                                                    <td>
                                                                                                        <input 
                                                                                                            type="checkbox"
                                                                                                            checked={canasta.status}
                                                                                                            onChange={(e) => handleCheckboxChange(col, false, idx, e.target.checked)}
                                                                                                        />
                                                                                                    </td>
                                                                                                </tr>
                                                                                                ))}
                                                                                            </tbody>
                                                                                            </table>
                                                                                        ) : (
                                                                                            <div className="ticket-empty-modal pt-4">
                                                                                                <div><i className="fas fa-box-open fs-20"></i></div>
                                                                                                <div className="text-empty">En este momento no hay nada para mostrar</div>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            ))
                                                                ))                              
                                                                ) : (
                                                                    <div className="ticket-empty-modal pt-4">
                                                                        <div><i className="fas fa-box-open fs-20"></i></div>
                                                                        <div className="text-empty">En este momento no hay nada para mostrar</div>
                                                                    </div>
                                                                )}
                                                            </div>

                                                                
                                                            </div>
                                                        )}

                                                    {type === 'canastas' && (
                                                        <div className='mt-4'>
                                                            <div className="accordion" id="accordionExample">
                                                                {  listTypes&& listTypes.length > 0 ? (
                                                                listTypes.map((item, index) => (
                                                                    <div className="card mb-1" key={index}>
                                                                    <div 
                                                                    className="p-2 border-bottom cursor-pointer fw-600" 
                                                                    id={`heading${index}`}
                                                                        data-toggle="collapse" 
                                                                        data-target={`#collapse${index}`} 
                                                                        aria-expanded={index === 0 ? 'true' : 'false'} 
                                                                        aria-controls={`collapse${index}`}
                                                                    >
                                                                        
                                                                        Canasta: {item.cod_ubicacion}
                                                                        
                                                                    </div>
                                                                    <div id={`collapse${index}`} 
                                                                    className={`collapse ${index === 0 ? 'show' : ''}`} 
                                                                    aria-labelledby={`heading${index}`} 
                                                                    data-parent="#accordionExample">
                                                                        <div className="card-body p-1">
                                                                            <div className="header-canastas">
                                                                            {
                                                                                item.referencias&& item.referencias.length>0 ?
                                                                                <table className="table" key={index+'n'}>
                                                                                <thead>
                                                                                    <tr>
                                                                                    <th className='m-2'>Referencia</th>
                                                                                    <th className='m-2'>Cant</th>
                                                                                    <th className='m-2'>
                                                                                        <input
                                                                                            key={'n'}
                                                                                            name={`${item.cod_ubicacion}-all`}
                                                                                            id="all"
                                                                                            type="checkbox"
                                                                                            className='form-control big-check' 
                                                                                            onChange={(e) => handleCheckboxChange(item, true, null, e.target.checked)}
                                                                                            checked={null}
                                                                                        />
                                                                                    </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {item&& item.referencias.map((ref, idf) => (
                                                                                    <tr key={idf}>
                                                                                        <td>{ref.referencia}</td>
                                                                                        <td>{ref.cantidad}</td>
                                                                                        <td>
                                                                                            <input 
                                                                                                type="checkbox"
                                                                                                checked={ref.status}
                                                                                                onChange={(e) => handleCheckboxChange(item, false, idf, e.target.checked)}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                                </table>
                                                                                :
                                                                                <div className="ticket-empty-modal pt-4">
                                                                                    <div><i className="fas fa-box-open fs-20"></i></div>
                                                                                    <div className="text-empty">En este momento no hay nada para mostrar</div>
                                                                                </div>
                                                                            }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                ))
                                                                ) : (
                                                                    <div className="ticket-empty-modal pt-4">
                                                                        <div><i className="fas fa-box-open fs-20"></i></div>
                                                                        <div className="text-empty">En este momento no hay nada para mostrar</div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                    </section>
                                            <section className="btn-float">
                                                { checkedList.length>0 &&
                                                    <div className="btn-solid-red" onClick={deleteLocation}>Eliminar</div>
                                                }
                                                
                                            </section>
                                        </>
                                }
                        </div>
                    )
                }
                {
                    activeTab === 'transferir' && (
                        <Transfer listBodegas={listBodegas&& listBodegas}/>
                    )
                }
                {
                    activeTab === 'historial' && (
                        <History listBodegas={listBodegas&& listBodegas}/>
                    )
                }
                {
                    activeTab === 'masivas' && (
                        <Masivas listBodegas={listBodegas&& listBodegas}/>
                    )
                }
            </div>
        </div>
    )
}
export default Index;