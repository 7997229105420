import React, { useContext } from 'react';
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom';

// layouts
import { LayoutMain } from '../layouts/LayoutMain';
import PublicLayout from '../layouts/PublicLayout';
import { Login } from '../layouts/Login';

// context
import AuthContext from '../contexts/auth/AuthContext';

const LayoutMainRoutes = (props) => {   
    const { isAuthenticated } = useContext(AuthContext);

    const requireAuth = (Component) => {
        if (isAuthenticated) {
            return Component;
        } else {
            let route = "/Login";
            if (window.location.pathname !== '') {
                route = route + "?next=" + window.location.pathname;
            }
            return <Redirect to={route} />;
        }
    };

    return (
        <Router>
            <Switch>
                <Route exact path='/Login' component={Login}/>
                
                <Route 
                    exact 
                    path='/ruta-principal' 
                    render={() => requireAuth(<LayoutMain />)} 
                />
                
                <Route 
                    exact 
                    path='/consultar-referencias' 
                    render={() => requireAuth(<PublicLayout />)} 
                />

                <Route path='/' render={() => requireAuth(<LayoutMain />)} />
            </Switch>
        </Router>
    );
};

export {
    LayoutMainRoutes
};
