import React, {useState, useEffect, useMemo, useCallback, useContext} from 'react';
import Call from '../../../config/Call'
import Spinner from '../../base/spiner';
import {TitleLayout} from '../../base/TitleLayout'
import { Calendar, Views, momentLocalizer  } from 'react-big-calendar'
import Swal from 'sweetalert2'
import moment from 'moment'
import AuthContext from '../../../contexts/auth/AuthContext';


const localizer = momentLocalizer(moment)
// let allViews = Object.keys(Views).map((k) => Views[k])
// let timeout;
// let currentValue;

const Citas =(props)=>{

    let datat = {
        titulo: "Citas bodega" //SECTION TITLE
    }
    
    const [sp, setSp] = useState(false) // SPINNER
    const { user } = useContext(AuthContext) // INFORMACIÓN DE USUARIO
    const [gates, setGates] =useState('') // DEFAULT DOOR
    const [listGates, setListGates]= useState([]) //LIST DOOR
    const [myEvents, setEvents] = useState([]) // LIST APPOINTMENTS
    let [showModal, setShowModal]=useState(false) // SHOW/HIDE MODAL CREATE AND SEE  APPOINTMENTS
    const [infoModal, setInfoModal ]=useState({ //MODAL DATA
      title:'',
      op:'',
      model:'',
      program:'',
      provider:'',
      quantity:'',
      registered:'',
      subline:'',
      missing:'',
      id:''
    })
    const [modeModal, setModeModal]=useState('create') // MODAL TYPE
    const [showdetailModal, setShowDetailModal]= useState(false) // SHOW INFO MODAL
    const [showInfoOp,setShowInfoOp]=useState(false)
    const [errorModal, setErrorModal]=useState('')
    const [showCalendar, setShowCalendar]=useState(false)
    
    let [view, setView]=useState('week') //GET TO KNOW THE VIEW OF THE CURRENT CALENDAR
    const lang = {
        //TRANSLATE CALENDAR LANGUAGE
        es: {
          week: 'Semana',
          work_week: 'Semana de trabajo',
          day: 'Día',
          month: 'Mes',
          previous: 'Atrás',
          next: 'Siguiente',
          today: 'Hoy',
          agenda: 'Agenda',
          event:'Evento',
          date:'Fecha',
          time: 'Hora',
          noEventsInRange: 'No hay eventos en este rango de fechas',
      
          showMore: (total) => `+${total} más`,
        },
    }
    const today = new Date();

// start time 8:00am
    let min=(
      new Date(
        today.getFullYear(), 
        today.getMonth(), 
        today.getDate(), 
        7
      ))
    
   
    useEffect(()=>{
        getListGates()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(()=>{
      
    }, [view, infoModal.op, myEvents])    
        

    const getListGates=async()=>{
        //GATE LIST
        try {
            setSp(true)
            const res = await Call('GET', 'api/winery_quotes/gates', true, null, false)
            if(res.data.code === 1){
                setGates(res.data.data[0].id)
                setListGates(res.data.data)
                if(res.data.data.length !== 0){
                    listAppointments(res.data.data[0].id)
                }
                
            }else{
                setSp(false)
                Swal.fire({
                    title: '',
                    icon: 'error',
                    html: res.data.message,
                    focusConfirm: false,
                    confirmButtonText:'Aceptar',
                }) 
            }
        } catch (error) {
            setSp(false)
        }
    }
    const getDrilldownView = useCallback(
      (targetDate, currentViewName, configuredViewNames) => {
        view= currentViewName;
        if (currentViewName === 'month' && configuredViewNames.includes('week'))
          return 'week'

          
          return null
      },[]
    )
  
    const handleChange =(e)=>{
        //DATA FORM CREATE APPOINTMENT
      e.preventDefault()
        setInfoModal({
            ...infoModal,
            [e.target.name]:e.target.value
          })
    }
    const handleInputChange = (e) => {
        //CHANGE OP
        setInfoModal({...infoModal, op:e.target.value});
        
        if(e.target.value === ''){
            resetModal()
        }
        
    };
    const onView = useCallback((newView) => setView(newView), [setView]) //CHANGE VIEW DEFAULT
    const handleSelectSlot = useCallback(
        //SELECTED SCHEDULE
        ({ start, end}) => {
            if((view !== 'month') && (user.nombre_cargo === 'ENLACE DE PRODUCCION' || user.centro_costos === 'AREA SISTEMAS' || user.nombre_cargo === 'DIRECTOR DE PRODUCCION' || user.nombre_cargo !== 'PORTERO' ||  user.nombre_cargo === 'DIRECTOR DE OPERACIONES' ||  user.nombre_cargo === 'AUXILIAR PRODUCCION')){
                infoModal.start=start
                infoModal.end=end
                setView(view)
            }    
        },
        []
    )
    const newFunction=(start, info, infom)=>{
        
        if(infoModal.model !== '' ){
            if(view!== 'month'){
                if(infoModal&& ((infoModal.registered > infoModal.quantity) && infoModal.missing === 0)){
                    Swal.fire({
                        title: '',
                        icon: 'info',
                        html: 'No hay unidades pendientes por ingresar',
                        focusConfirm: false,
                        confirmButtonText:'Aceptar',
                    }) 
                }else{
                    setInfoModal({
                        ...infoModal,
                        start:start.start,
                        end:start.end
                    })
                    setShowModal(true)
                }
                
            }else{
                Swal.fire({
                    title: '',
                    icon: 'info',
                    html: 'Ingresa al día deseado y selecciona el horario de la cita',
                    focusConfirm: false,
                    confirmButtonText:'Aceptar',
                }) 
            }
        }else{
            Swal.fire({
                title: '',
                icon: 'info',
                html: 'Consulta la información de una OP',
                focusConfirm: false,
                confirmButtonText:'Aceptar',
            }) 
        }
    }
    const onSelectEvent = useCallback((calEvent) => {
        //SELECTED APPOINTMENT
        setShowModal(true)
        setModeModal('see')
        setInfoModal(calEvent)
    }, [])

    const saveInfo=async()=>{
        //SAVE INFO APPOINTMENTS
        if(infoModal.program === ''){
            setErrorModal('Este campo es obligatorio')
        }else if(infoModal.program <= '0' || infoModal.program <= 0 ){
            setErrorModal('Ingrese una cantidad mayor a 0')
        }else{
            setErrorModal('')
            const opcionesFormato = {
                timeZone: "UTC",
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                fractionalSecondDigits: 0 // Added to exclude milliseconds
            };
            
            const fechaOriginal = new Date(infoModal.start);
            fechaOriginal.setMinutes(fechaOriginal.getMinutes() - (5 * 60));
            const fechaFormateadaISO = fechaOriginal.toISOString(opcionesFormato);
            const fechaFinal = fechaFormateadaISO.slice(0, -5);
            
            const fechaOriginal2 = new Date(infoModal.end);
            fechaOriginal2.setMinutes(fechaOriginal2.getMinutes() - (5 * 60));
            const fechaFormateadaISO2 = fechaOriginal2.toISOString(opcionesFormato);
            const fechaFinal2 = fechaFormateadaISO2.slice(0, -5);        

            let data={      
                quote: {
                    op: infoModal&& infoModal.op,
                    title: (`OP: ${infoModal&& infoModal.op} - ${infoModal&& infoModal.provider.toLowerCase()} - Uds: ${infoModal&& infoModal.quantity} - Uds recibir: ${infoModal && infoModal.program}`),
                    quantity: infoModal.program,
                    start_time: fechaFinal,
                    end_time: fechaFinal2,
                    gate: gates
                }
                
            }
            Swal.fire({
                title: "",
                text: "¿Desea crear la cita?",
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Crear cita",
                cancelButtonText:"Cancelar"
              }).then(async(result) => {
                if (result.isConfirmed) {
                    try {
                        setSp(true)
                        const res = await Call('POST', '/api/winery_quotes/create/quote', true, data, false)
                        if(res.data.code ===1){
                            if(res.data.data[0].quotes.length !== 0){
                                const list=[]
                                let data
                                
                                for(let i=0; i< res.data.data[0].quotes.length; i++){
                                    
                                    data={
                                        id:res.data.data[0].quotes[i]&& res.data.data[0].quotes[i].id,
                                        title:res.data.data[0].quotes[i]&& res.data.data[0].quotes[i].title,
                                        start:res.data.data[0].quotes[i]&& new Date(res.data.data[0].quotes[i].start_time),
                                        end:res.data.data[0].quotes[i]&& new Date(res.data.data[0].quotes[i].end_time),
                                        op:res.data.data[0].quotes[i]&& res.data.data[0].quotes[i].op,
                                        quantity:res.data.data[0].quotes[i]&& res.data.data[0].quotes[i].quantity,
                                        model:res.data.data[0].quotes[i]&& res.data.data[0].quotes[i].model,
                                        provider: res.data.data[0].quotes[i]&& res.data.data[0].quotes[i].provider,
                                        subline: res.data.data[0].quotes[i]&& res.data.data[0].quotes[i].subline,
                                    }
                                    list.push(data)
                                }
                                setEvents(list)
                                // resetModal()
                                setShowModal(false)
                                setSp(false)
                            }
                        }
                        setSp(false)
                    } catch (error) {
                        setSp(false)
                        resetModal()
                        setShowModal(false)
                    }
                }
              });
            
        }
    }

    const { defaultDate, max, scrollToTime, messages} = useMemo(
        () => ({
          defaultDate: new Date(2015, 3, 13),
            //   max: dates.add(dates.endOf(new Date(2015, 17, 1), 'day'), -1, 'hours'),
            scrollToTime: new Date(1970, 1, 1, 6),
            messages: lang['es']
        }),
        []
    )
    const formatDate = fecha=>{
        //DATE FORMAT SHOW IN MODAL
        const fechaNueva = new Date(fecha)
        const hora = fechaNueva.getHours();
        const ampm = hora >= 12 ? "PM" : "AM";
        const opciones={
            year:'numeric',
            month: 'long',
            day:'2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
            hourSuffix: ampm,
        }
        return fechaNueva.toLocaleDateString('es-ES', opciones)
    }
    const resetModal =()=>{
        // CLEAN DATA MODAL
        setShowDetailModal(false)
        setShowInfoOp(false)
        setModeModal('create')
        setErrorModal('')
        setInfoModal({
            id:'',
            title:'',
            op:'',
            model:'',
            provider:'',
            quantity:'',
            program:'',
            registered:'',
            subline:'',
            missing:'',
            start:infoModal.start,
            end:infoModal.end,
        })
    }  
   
    const listAppointments=async(gates)=>{
        try{
            setSp(true)
            const res = await Call('GET', 'api/winery_quotes/quotes/'+gates, true, false)
            if(res.data.code ===1){
                if(res.data.data[0].quotes.length !== 0){
                    const list=[]
                    let data
                    
                    for(let i=0; i< res.data.data[0].quotes.length; i++){
                        
                        data={
                            id: res.data.data[0].quotes[i]&& res.data.data[0].quotes[i].id,
                            title:res.data.data[0].quotes[i]&& res.data.data[0].quotes[i].title,
                            start:res.data.data[0].quotes[i]&& new Date(res.data.data[0].quotes[i].start_time),
                            end:res.data.data[0].quotes[i]&& new Date(res.data.data[0].quotes[i].end_time),
                            op:res.data.data[0].quotes[i]&& res.data.data[0].quotes[i].op,
                            quantity:res.data.data[0].quotes[i]&& res.data.data[0].quotes[i].quantity,
                            model:res.data.data[0].quotes[i]&& res.data.data[0].quotes[i].model,
                            provider: res.data.data[0].quotes[i]&& res.data.data[0].quotes[i].provider,
                            subline: res.data.data[0].quotes[i]&& res.data.data[0].quotes[i].subline,
                        }
                        list.push(data)
                    }
                    setEvents(list)
                    setShowCalendar(true)
                    setSp(false)
                }else{
                    setShowCalendar(true)
                    setEvents([])
                    setSp(false)
                }
            }else{
                if(res.data.data.length === 0){
                    setEvents([])
                    setShowCalendar(true)
                }
                setSp(false)
                Swal.fire({
                    title: '',
                    icon: 'error',
                    html: res.data.message,
                    focusConfirm: false,
                    confirmButtonText:'Aceptar',
                }) 
            }
            
        }catch(error){
            if(error.response.data.data.length === 0){
                setShowCalendar(true)
                setEvents([])
            }
            setSp(false)
        }
    }
   //SEARCH OP       
   
    const fet=async (value) =>{
        //SEARCH OP
        
        if(value !== ''){
            try {
                setSp(true)
                const res = await Call('GET', 'api/winery_quotes/op/details/'+value, true, null, false)
                if(res.data.code === 1){
                    setInfoModal(Object.assign({}, infoModal, res.data.data[0]))                  
                    setShowInfoOp(true)
                    setShowDetailModal(true)
                    setSp(false)
                }else{
                    setSp(false)
                    Swal.fire({
                        title: '',
                        icon: 'error',
                        html: res.data.data.message,
                        focusConfirm: false,
                        confirmButtonText:'Aceptar',
                    }) 
                }             
            } catch (error) {
                setSp(false)
                resetModal()
            }
            
        }
    }
    // DELETE APPOINTMENTS
    const deleteAppointements = async(id)=>{
        Swal.fire({
            title: "",
            text: "¿Desea eliminar la cita?",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar cita",
            cancelButtonText:"Cancelar"
          }).then(async(result) => {
                if (result.isConfirmed) {
                    try {
                        setSp(true)
                        const res= await Call('POST', `/api/winery_quotes/delete/${id}/${gates}`, true, null, false)
                        if(res.data.code ===1){
                            if(res.data.data.length > 0){                            
                                if(res.data.data[0].quotes.length !== 0){
                                    const list=[]
                                    let data
                                    
                                    for(let i=0; i< res.data.data[0].quotes.length; i++){
                                        
                                        data={
                                            id: res.data.data[0].quotes[i]&& res.data.data[0].quotes[i].id,
                                            title:res.data.data[0].quotes[i]&& res.data.data[0].quotes[i].title,
                                            start:res.data.data[0].quotes[i]&& new Date(res.data.data[0].quotes[i].start_time),
                                            end:res.data.data[0].quotes[i]&& new Date(res.data.data[0].quotes[i].end_time),
                                            op:res.data.data[0].quotes[i]&& res.data.data[0].quotes[i].op,
                                            quantity:res.data.data[0].quotes[i]&& res.data.data[0].quotes[i].quantity,
                                            model:res.data.data[0].quotes[i]&& res.data.data[0].quotes[i].model,
                                            provider: res.data.data[0].quotes[i]&& res.data.data[0].quotes[i].provider,
                                            subline: res.data.data[0].quotes[i]&& res.data.data[0].quotes[i].subline,
                                        }
                                        list.push(data)
                                    }
                                    setEvents(list)
                                    resetModal()
                                    setShowModal(false)
                                    setSp(false)
                                }else{
                                    resetModal()
                                    setShowModal(false)
                                    setEvents([])
                                    setSp(false)
                                }
                            }else{
                                resetModal()
                                setShowModal(false)
                                setEvents([])
                                setSp(false)
                            }
                        }else{
                            setSp(false)
                        }
                        
                    }catch (error) {                      
                        
                        setShowModal(false)
                        resetModal()
                        setSp(false)
                    }
                }
          });       
    }
    return(
        <div className='pb-4'>
            { sp ? <Spinner /> : ''}  {/*LOADER*/}
            <TitleLayout data={datat} clase={"text-left"} icon={true} seccion={'citas'} />
            <div className='bg-white p-4 col-lg-10 col-md-10 col-sm-12 mx-auto' style={{height:'auto'}}>
              { showModal && 
              <>
                <div  className="modal" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
                  <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document" >
                    <div className="modal-content position-relative">
                      <div className="modal-header py-1">
                        <h5 className="modal-title">{modeModal === 'see'? 'Cita' : 'Crear cita'}</h5>
                        <button type="button" onClick={()=>{setShowModal(false); setModeModal('create'); resetModal()}} className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body m-regular fs-15 box-scroll" style={{maxHeight:'70vh', overflowY:'auto'}}  >
                        {
                            modeModal === 'see' ?
                                <div className='m-regular'>
                                    <p className='fw-600 m-regular'>{infoModal&& infoModal.op +' - '+ infoModal.title}</p>
                                    <p className='m-regular'>Sublinea: {infoModal&& infoModal.subline}</p>
                                    <p className='m-regular'>Modelo: {infoModal&& infoModal.model}</p>
                                    <p className='m-regular'>Cantidad: {infoModal&& infoModal.quantity}</p>
                                    <p className='m-regular text-capitalize'>Confeccionista: {infoModal&& infoModal.provider&& infoModal.provider.toLocaleLowerCase()} </p>
                                    <p className='m-regular'><span className='fw-600'>Hora inicio: {infoModal&& formatDate(infoModal.start.toString())}</span></p>
                                    <p className='m-regular fs-14'><span className='fw-600'>Hora fin: {infoModal&& formatDate(infoModal.end.toString())}</span></p>

                                </div>
                            :
                                <div className='w-100 text-label fs-14'>
                                    <div className='my-2'>
                                    <span>Op: </span>{infoModal&& infoModal.op}
                                </div>
                                    
                                    {
                                        showdetailModal &&
                                        <>                                   
                                            <div className='my-2'>
                                                <span>Sublinea: </span>{infoModal&& infoModal.subline}
                                            </div>
                                            <div className='my-2'>
                                                <span>Modelo: </span>{infoModal&& infoModal.model}
                                            </div>
                                            <div className='my-2'>
                                                <span>Total unidades agendadas:</span>{infoModal&& infoModal.scheduled_units}
                                            </div>
                                            <div className='my-2'>
                                                <span>Cantidad:</span>{infoModal&& infoModal.quantity}
                                            </div>
                                            <div className='my-2'>
                                                <span>Recibidos:</span>{infoModal&& infoModal.registered}
                                            </div>
                                            <div className='my-2'>
                                                <span>Faltantes:{infoModal&& infoModal.missing}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="program" className='fs-14 text-label'>Cantidad a recibir</label>
                                                <input 
                                                type="number" 
                                                className="form-control form-control-sm" 
                                                id="program"
                                                name="program"
                                                value={infoModal&& infoModal.program} 
                                                onChange={handleChange}                                        
                                                />
                                                {errorModal !=='' && <span className='text-danger font-italic'>{errorModal}</span>}
                                            </div>
                                            <div>
                                                <span>Confeccionista: </span><span className='text-capitalize'>{infoModal&& infoModal.provider.toLocaleLowerCase()} </span>
                                            </div>
                                            <div className='d-grid mt-2'>
                                                <span className='fw-600'>Hora inicio: {infoModal&& formatDate(infoModal.start.toString())}</span>
                                                <span className='fw-600'>Hora fin: {infoModal&& formatDate(infoModal.end.toString())}</span>
                                            </div>
                                        </>
                                    }
                            </div>
                        }  
                      </div>
                     
                       
                        {
                            modeModal !== 'see' ? 
                                <div className="modal-footer">
                                    <div>
                                        <button type="button" className="btn-cancel py-1 px-3" onClick={()=>{resetModal(); setShowModal(false)}}>Cancelar</button>
                                        <button type="button" className="btn-info2 px-3 py-1 mx-2" onClick={saveInfo}>Asignar cita</button>
                                    </div>
                                </div>
                                :
                                <div>
                                    {
                                        ((user.nombre_cargo === 'ENLACE DE PRODUCCION') || (user.centro_costos === 'AREA SISTEMAS') || (user.nombre_cargo === 'DIRECTOR DE PRODUCCION') || (user.nombre_cargo === 'DIRECTOR DE OPERACIONES') ||  (user.nombre_cargo === 'AUXILIAR PRODUCCION')) ?
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-primary py-1 px-3" onClick={()=>{deleteAppointements(infoModal.id)}}>Eliminar</button>
                                            </div>
                                            :''
                                    }
                                    
                                </div>
                        }
                        
                    </div>
                  </div>
                </div>
                <div className='modal-backdrop show'></div>
                </>
              }
                <div className='d-flex mr-2 flex-wrap mb-3'>
                    <div className='col-2 flex-fill pl-0'>
                        {
                            listGates&& listGates.length!== 0 &&
                                <div className="form-group">
                                    <label 
                                        htmlFor="door"
                                        className='fs-14 m-regular fw-500  text-label'
                                    >
                                        Puertas
                                    </label>
                                    <select 
                                        className="custom-select custom-select-sm fs-14" 
                                        id="door"
                                        name="gate"
                                        style={{width:'100px'}}
                                        onChange={(e)=>{setGates(e.target.value);listAppointments(e.target.value)}}
                                        value={gates}
                                    >
                                    {
                                        listGates&& listGates.map((g, indexG)=>(
                                            <option value={g.id} key={indexG} >{g.name}</option>
                                        ))
                                    }
                                    </select>
                                </div>
                        }
                    </div>

                    { showCalendar && ((user.nombre_cargo === 'ENLACE DE PRODUCCION') || (user.centro_costos === 'AREA SISTEMAS') ||( user.nombre_cargo === 'DIRECTOR DE PRODUCCION') || (user.nombre_cargo === 'DIRECTOR DE OPERACIONES') ||  (user.nombre_cargo === 'AUXILIAR PRODUCCION'))&&
                        <div className='flex-fill'>
                            <div className="form-group mb-0 d-flex">
                                <label htmlFor="op" className='fs-14 text-label text-left'>OP</label>
                                <div className='w-100 mb-0 d-flex'>
                                    <input type="number"
                                    className='form-control form-control-sm col-4'
                                    placeholder='Ingrese la Op'
                                    name="op"
                                    id="op"
                                    value={infoModal&& infoModal.op}
                                    onChange={handleInputChange}                       
                                    />
                                    <button className='btn-info2 mx-2' onClick={()=>fet(infoModal.op)}>Consultar</button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {
                    showInfoOp &&
                    <section className='my-3'>
                        <div className="alert-info p-2 text-dark m-regular d-flex justify-content-between w-100" role="alert">
                            <div>
                                <span className='text-capitalize fs-15 text-dark'><span className='fw-600'>Sublinea:</span> {infoModal.subline&& infoModal.subline.toLowerCase()} - </span> 
                                <span className='text-capitalize fs-15 text-dark'><span className='fw-600'>Modelo: </span>{infoModal.model&& infoModal.model.toLowerCase()}</span>
                                <p className='my-0 m-regular fs-15 text-dark'><span className='fw-600'>Unidades agendadas: {infoModal&& infoModal.scheduled_units}</span></p>
                                <p className='m-regular fs-15 c-sec mb-0'>
                                    <span className='text-capitalize text-dark'><span className='fw-600'>Cantidad: </span>{infoModal.quantity&& infoModal.quantity}</span>
                                    <span className='text-capitalize text-dark'> - <span className='fw-600'>Recibidas:</span> {infoModal.registered && infoModal.registered}</span>
                                    <span className='text-capitalize text-dark'> - <span className='fw-600'>Faltantes:</span> {infoModal.missing && infoModal.missing}</span>
                                </p>
                                <p className='my-0 m-regular fs-15 text-dark'>
                                    <span className='text-capitalize text-dark'><span className='fw-600'>Confeccionista:</span> {infoModal.provider && infoModal.provider.toLowerCase()}</span>
                                </p>
                            </div>
                            <div><i className="fas fa-times fs-16 cursor-pointer" onClick={resetModal}></i></div>
                        </div>
                    </section>
                }
                    {
                        showCalendar &&
                        <div className="height600">
                            <Calendar
                                localizer={localizer}
                                events={myEvents}
                                startAccessor="start"
                                endAccessor="end"
                                selectable
                                onSelectEvent={onSelectEvent}
                                onSelectSlot={(user.nombre_cargo === 'ENLACE DE PRODUCCION' || user.centro_costos === 'AREA SISTEMAS' || user.nombre_cargo === 'DIRECTOR DE PRODUCCION' || user.nombre_cargo === 'DIRECTOR DE OPERACIONES' ||  user.nombre_cargo === 'AUXILIAR PRODUCCION')&&((start, end)=>{handleSelectSlot(start, end); newFunction(start, end, infoModal, view)})}
                                scrollToTime={scrollToTime}
                                style={{ height: '100vh' }}
                                messages={messages}
                                onView={onView}
                                step={15}
                                view={view}
                                min={min}
                                getDrilldownView={getDrilldownView}
                            />
                        </div>
                    }

                    {
                        listGates && listGates.length === 0 ?
                        
                            <div className="ticket-empty-modal pb-4 mb-4">
                                <div><i className="fas fa-box-open fs-20"></i></div>
                                <div className="text-empty">En este momento no hay puertas activas, comuníquese con el administrador</div>
                            </div>
                        :
                        ''
                    }  
            </div>
        </div>
    )
}
export default Citas;