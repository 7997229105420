import React, {useContext} from 'react';
import { Link, NavLink } from "react-router-dom";
import { Tooltip } from 'antd';
import logo from '../../../assets/base/images/cubo_rojo.ico'; // with import

import AuthContext from '../../../contexts/auth/AuthContext';

export const SideBar = () => {
    const { menu } = useContext(AuthContext)
    let seccion = []
    for (let it in menu){
        let val = menu[it]
        seccion.push({header:true,name:val.seccion_name})
        if (val.items || val.items.length() >0){
            for(let it2 in val.items){
                let item = val.items[it2]
                if(item.dropdown===false){
                    let obj = {
                        name: item.item_name,
                        icon: item.icon,
                        url: item.url_absolute===null?``:`${item.url_absolute}`,
                        front:item.front
                    }
                    seccion.push(obj)
                }else{
                    let objSubitem = []
                    for (let sitm in item.subitems){
                        let subitem = item.subitems[sitm]
                        let subobj = {
                            name: subitem.item_name,
                            url: subitem.url_absolute===null?``:`${subitem.url_absolute}`,
                            front:subitem.front,
                            is_external:subitem.is_external
                        }
                        objSubitem.push(subobj)
                    }
                    let obj ={
                        dropdown: true,
                        name: item.item_name,
                        icon: item.icon,
                        children: objSubitem
                    }
                    seccion.push(obj)
                }
            }
        }
    }
    
    const Data ={
        menus:seccion
    };
    
    return (
        <>
            <div className="main-sidebar">
                <aside id="sidebar-wrapper">
                <div className="sidebar-brand">
                    <Link to="/"> <img  src={logo} width="30px" height="30px" alt=""/> Big John </Link>{" "}
                </div>{" "}
                <div className="sidebar-brand sidebar-brand-sm">
                    <Link to="/"> <img  src={logo} width="30px" height="30px"  alt=""/></Link>{" "}
                </div>{" "}
                <ul className="sidebar-menu mb-5">
                    {" "}
                    {Data.menus.map((menu, iMenu) => {
                        
                    let comp;
                    if (menu.header) {
                        // HEADER DEL MENU
                        comp = (
                        <Tooltip key={iMenu} placement="topLeft" title={menu.name}>   
                        {/* NOMBRE SECCION */}
                        <li key={iMenu} className="menu-header">
                            {" "}
                            {menu.name}{" "}
                        </li>
                        </Tooltip>
                        );
                    } else if (menu.dropdown) {
                        if (menu.active) {
                        comp = (
                            <li key={iMenu} className="nav-item dropdown active">
                            <Link to="#" className="nav-link has-dropdown">
                            <i className={menu.icon} /> <span> {menu.name} </span>
                            </Link>{" "}
                            <ul className="dropdown-menu">
                                {" "}
                                {menu.children.map((submenu, iSubmenu) => {
                                let subComp;
                                if (submenu.active) {
                                    if (submenu.beep) {
                                    subComp = (
                                        <Tooltip  key={iSubmenu} placement="topLeft" title={submenu.name}>  
                                        <li key={iSubmenu} className="active">
                                            {submenu.front==='react'?<NavLink
                                            
                                            // exact={true}
                                            className="beep beep-sidebar"
                                            to={`/${submenu.url}`}
                                        >
                                            
                                            {submenu.name}
                                        </NavLink>:<a href={`${process.env.REACT_APP_MENU_URL}${submenu.url}`} > <i className={submenu.icon} /> <span> {submenu.name} </span></a>}

                                        
                                        </li>
                                        </Tooltip>
                                    );
                                    } else {
                                    subComp = (
                                        <li key={iSubmenu}>
                                        {submenu.front==='react'?<NavLink
                                            
                                            // exact={true}
                                            to={`/${submenu.url}`}
                                        >
                                            
                                            {submenu.name} aca
                                            </NavLink>:<a href={`${process.env.REACT_APP_MENU_URL}${submenu.url}`} > <i className={submenu.icon} /> <span> {submenu.name} </span></a>}
                                            </li>
                                    );
                                    }
                                } else if (submenu.beep) {
                                    subComp = (
                                    <li key={iSubmenu}>
                                        {submenu.front==='react'?<NavLink
                                        
                                        // exact={true}
                                        className="beep beep-sidebar"
                                        to={`/${submenu.url}`}
                                        >
                                        
                                        {submenu.name}
                                        </NavLink>:<a href={`${process.env.REACT_APP_MENU_URL}${submenu.url} p-x-`} > <i className={submenu.icon} /> <span> {submenu.name} </span></a>}
                                                     </li>
                                    );
                                } else {
                                    subComp = (
                                    <li key={iSubmenu}>
                                        {submenu.front==='react'?<NavLink
                                       
                                        // exact={true}
                                        to={`/${submenu.url}`}
                                        >
                                        
                                        {submenu.name}aca si
                                        </NavLink>:<a href={`${process.env.REACT_APP_MENU_URL}${submenu.url}`} > <i className={submenu.icon} /> <span> {submenu.name} </span></a>}
                                    </li>
                                    );
                                }

                                return subComp;
                                })}{" "}
                            </ul>{" "}
                            </li>
                        );
                        } else {
                        comp = (
                            <li key={iMenu} className="nav-item dropdown">
                    
                            <Link to="#" className="nav-link has-dropdown">
                            <i className={menu.icon} /> <span> {menu.name}</span>
                            </Link>{" "}
                            <ul className="dropdown-menu">
                                {" "}
                                {menu.children.map((submenu, iSubmenu) => {
                                let subComp;
                                if (submenu.active) {
                                    if (submenu.beep) {
                                    subComp = (
                                        <li key={iSubmenu} className="active">
                                        {submenu.front==='react'?<NavLink
                                           
                                            // exact={true}
                                            className="beep beep-sidebar"
                                            to={`/${submenu.url}`}
                                        >
                                            {submenu.name}AQUI
                                            </NavLink>:<a href={`${process.env.REACT_APP_MENU_URL}${submenu.url}`} > <i className={submenu.icon} /> <span> {submenu.name} ACA</span></a>}
                                            </li>
                                    );
                                    } else {
                                    subComp = (
                                        <li key={iSubmenu} className="active">
                                        {submenu.front==='react'? <NavLink
                                           
                                            // exact={true}
                                            to={`/${submenu.url}`}
                                        >
                                            
                                            {submenu.name}AQUI2
                                            </NavLink>:<a href={`${process.env.REACT_APP_MENU_URL}${submenu.url}`} > <i className={submenu.icon} /> <span> {submenu.name} </span></a>}
                                            </li>
                                    );
                                    }
                                } else if (submenu.beep) {
                                    subComp = (
                                    <li key={iSubmenu}>
                                        {submenu.front==='react'? <NavLink
                                        
                                        // exact={true}
                                        className="beep beep-sidebar"
                                        to={`/${submenu.url}`}
                                        >
                                        {submenu.name}TT
                                        </NavLink>:<a href={`${process.env.REACT_APP_MENU_URL}${submenu.url}`} > <i className={submenu.icon} /> <span> {submenu.name} </span></a>}
                                    </li>
                                    );
                                } else {
                                    subComp = (
                                    <li key={iSubmenu}>
                                        {submenu.front === 'react' ? (
                                            submenu.is_external ? (
                                                <a
                                                    href={`/${submenu.url}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {submenu.name}
                                                </a>
                                            ) : (
                                                <NavLink to={`/${submenu.url}`}>
                                                    {submenu.name}
                                                </NavLink>
                                            )
                                        ) : (
                                            <a
                                                href={`${process.env.REACT_APP_MENU_URL}${submenu.url}`}
                                                className="p-0"
                                                target={submenu.is_external ? "_blank" : "_self"}
                                                rel={submenu.is_external ? "noopener noreferrer" : ""}
                                            >
                                                <i className={submenu.icon} /> 
                                                <span>{submenu.name}</span>
                                            </a>
                                        )}
                                    </li>
                                    );
                                }

                                return subComp;
                                })}{" "}
                            </ul>{" "}
                            </li>
                        );
                        }
                    } else if (menu.active) {
                        comp = (
                        <li key={iMenu} className="s">
                            {menu.front==='react'? 
                            <NavLink
                            
                            // exact={true}
                            to={`/${menu.url}`} 
                            >
                            <i className={menu.icon} /> <span> {menu.name} </span>
                            </NavLink>:<a href={`${process.env.REACT_APP_MENU_URL}${menu.url}`} > <i className={menu.icon} /> <span> {menu.name} </span></a>}
                                   </li>
                        );
                    } else {
                        //Single Component
                        comp = (
                        <li key={iMenu}>
                            {menu.front==='react'? <NavLink
                            
                            // exact={true}
                            to={`/${menu.url}`} 
                            >
                            <i className={menu.icon} /> <span> {menu.name} </span>
                            </NavLink>:<a href={`${process.env.REACT_APP_MENU_URL}${menu.url}`} > <i className={menu.icon} /> <span> {menu.name} </span></a>}
                           </li>
                        );
                    }

                    return comp;
                    })}{" "}
                </ul>{" "}
                </aside>{" "}
            </div>

        </>
    );
};