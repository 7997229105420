import React, { useState } from 'react';
import { Modal, Table, Empty } from 'antd';

const DetailModal = ({ visible, onClose, record, transitToggle }) => {
    const [imageError, setImageError] = useState(false);
    const { color, imagen, precio, talla, almacenes } = record || {};

    const handleImageError = () => {
        setImageError(true);
    };

    const almacenColumns = [
        {
            title: 'Nombre',
            dataIndex: 'almacen',
            key: 'almacen',
            width: 150,
            fixed: 'left',
            render: (text) => <span style={{ fontWeight: 'bold' }}>{text}</span>,
        },
        {
            title: 'Cantidad',
            dataIndex: 'cantidad',
            key: 'cantidad',
            align: 'center',
            width: 100,
            render: (text) => <span style={{ fontWeight: 'bold' }}>{text}</span>,
        },
        ...(transitToggle ? [
            {
                title: 'Transito',
                dataIndex: 'transito',
                key: 'transito',
                align: 'center',
                width: 100,
                render: (text) => <span style={{ fontWeight: 'bold' }}>{text}</span>,
            },
        ] : []),
    ];

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            footer={null}
            centered
            title="Detalles del Producto"
        >
            <div style={{ textAlign: 'center' }}>
                {imageError || !imagen ? (
                    <Empty description="No se encontró una imagen" />
                ) : (
                    <img
                        src={imagen}
                        alt="Imagen del Producto"
                        style={{ width: '100%', maxWidth: '300px', height: 'auto', marginBottom: '8px', marginTop: '1px' }}
                        onError={handleImageError}
                    />
                )}

                <p style={{ marginTop: '1px', fontWeight: 'bold' }}>
                    <strong>Color: {color || 'N/A'}</strong>
                </p>
                <p style={{ marginTop: '1px', fontWeight: 'bold' }}>
                    <strong>Precio: ${precio || 'N/A'}</strong>
                </p>
                <p style={{ marginTop: '1px', fontWeight: 'bold' }}>
                    <strong>Talla: {talla || 'N/A'} </strong>
                </p>

                {almacenes && almacenes.length > 0 ? (
                    <Table
                        columns={almacenColumns}
                        dataSource={almacenes}
                        rowKey={(record, index) => index}
                        pagination={false}
                        size="small"
                        style={{ marginTop: '10px' }}
                        scroll={{ y: 240 }}
                        sticky
                    />
                ) : (
                    <Empty description="No se encontraron almacenes" />
                )}
            </div>
        </Modal>
    );
};

export default DetailModal;
