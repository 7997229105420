import React, { useEffect, useState } from 'react';
import { Layout, Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { ExternalRouters } from '../Routes/ExternalRoutes.js';

const { Header, Content } = Layout;

const MyLayout = () => {
    const [hideHeader, setHideHeader] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            setHideHeader(screenWidth <= 1300 && screenWidth >= 1000);
        };
        
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Layout>
            {!hideHeader && (
                <Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#333333', padding: '5px 20px' }}>
                    <div className="logo" style={{ display: 'flex', alignItems: 'center' }}>
                        <a href="/" style={{ display: 'flex', alignItems: 'center', color: 'white', textDecoration: 'none' }}>
                            <img 
                                src="/static/media/cubo_rojo.0d26522b7aa5e844f49a.ico" 
                                width="30px" 
                                height="30px" 
                                alt="Logo" 
                                style={{ marginRight: '8px' }}
                            />
                            <span style={{ color: 'white', fontWeight: 'bold', fontSize: '18px' }}>Big John</span>
                        </a>
                    </div>
                    <Button
                        href="/"
                        type="primary"
                        icon={<LogoutOutlined />}
                        style={{ backgroundColor: '#ff4d4f', borderColor: '#ff4d4f' }}
                    >
                        Volver a la Intranet
                    </Button>
                </Header>
            )}
            <Content style={{ padding: 0, minHeight: '100vh', backgroundColor: '#fff' }}>
                <ExternalRouters />
            </Content>
        </Layout>
    );
};

export default MyLayout;
