import React, { useState, useEffect } from 'react';
import Call from '../../../../config/Call';
import Spinner from '../../../base/spiner';
import { Select } from 'antd';
import Swal from 'sweetalert2';
import { Upload, message } from "antd";
import { saveAs } from 'file-saver';
import { decode } from '../../../../config/crypto';

const { Option } = Select;

const Masivas = (props) => {
    const [sp, setSp] = useState(false);
    const [listBodegas, setListBodegas] = useState([]);
    const [data, setData] = useState({ bodega: '', fileList: [] });
    const [searchTerm] = useState('');
    const [locationsList, setLocationsList] = useState([]);
    const [columnsPerRow, setColumnsPerRow] = useState([]); 

    useEffect(() => {
        setListBodegas(props.listBodegas);
    }, [props.listBodegas]);

    useEffect(() => {
        const handleResize = () => {

            if (window.innerWidth >= 1700) {
                setColumnsPerRow(7);
            } else if (window.innerWidth >= 1400) {
                setColumnsPerRow(5); 
            } else {
                setColumnsPerRow(3);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    const onChangeImg = async ({ fileList }) =>{ 
        
        if(((fileList[fileList.length-1] && fileList[fileList.length-1].size) && ((fileList[fileList.length-1].size/1024/1024))> 8)){
            message.error('El archivo debe ser menor a 8M');
        }else if((fileList[fileList.length-1] && fileList[fileList.length-1].type === 'application/x-msdownload') || (fileList[fileList.length-1] && fileList[fileList.length-1].type === '')){
            message.error('El formato del archivo no es permitido');
        }else{
            setData({
                ...data,
                fileList })
            if(fileList.length > 0) {
                sendFile(fileList)
            }
        }   
    }

    const beforeUpload = (file) => {
        // const isJPG = file.type === 'image/jpeg';
        return false;
    }

    const sendFile = async(fileList) => {
        try {
            let file = fileList[0].originFileObj
            let formdata = new FormData()
            formdata.append('file', file)
            setSp(true)
            let token = decode(localStorage.getItem('authInfo')).token;
            let url = `${process.env.REACT_APP_BACKEND_URL}/api/bodegas/buscar_ubicacion/${data.bodega}`;
    
            fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formdata
            })
            .then(async (res) => {
                // Se verifica el estado de la respuesta antes de procesar el contenido
                if (res.status === 200) {
                    // Si el estado es 200, se procesa el archivo en blob y se descarga
                    let blob = await res.blob();
                    saveAs(blob, 'ubicaciones.xlsx');
                } else if (res.status === 400) {
                    // Si el estado es 400, se obtienen los datos de error del servidor y se muestra un mensaje
                    let errorData = await res.json();
                    Swal.fire('Error', errorData.message || 'Hubo un problema con la búsqueda', 'error');
                } else {
                    // Si la respuesta no es 200 ni 400, se muestra un mensaje de error genérico
                    Swal.fire('Error', 'Hubo un problema con la solicitud', 'error');
                }
            })
            .catch(error => {
                // Si ocurre algún error en la petición, se muestra un mensaje de error
                Swal.fire('Error', 'No se pudo procesar la solicitud', 'error');
            })
            .finally(() => {
                // Independientemente del resultado, se desactiva el spinner
                setSp(false)
            })
        } catch (error) {
            // Si ocurre algún error no contemplado en el try, se muestra un mensaje de error
            setSp(false)
            Swal.fire('Error', 'Ocurrió un error inesperado', 'error');
        }
    }
    

    const groupLocations = (locations, groupSize) => {
        let grouped = [];
        for (let i = 0; i < locations.length; i += groupSize) {
            grouped.push(locations.slice(i, i + groupSize));
        }
        return grouped;
    };

    const handleChangeSelect = (option) => {
        setData((prevData) => ({ ...prevData, bodega: option, fileList: [] }));
        setLocationsList([]);
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = '/assets/plantillas/ubicaciones_masivas/plantilla.xlsx';
        link.download = 'plantilla.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleSearch = async () => {
        try {

            if (!data.bodega || !searchTerm) {
                Swal.fire('Error', 'Por favor selecciona una bodega e ingresa un término de búsqueda', 'warning');
                return;
            }

            setSp(true);

            const res = await Call('GET', `/api/bodegas/historial_ubicaciones/${data.bodega}?search=${searchTerm}`, true,null,false);

            if (res.status === 400) {
                Swal.fire('Error', res.data.message || 'Hubo un problema con la búsqueda', 'error');
            } else if (res.status === 200) {
                setLocationsList(groupLocations(res.data.data, columnsPerRow));
            } else {
                Swal.fire('Error', 'Hubo un problema con la solicitud', 'error');
            }
        } catch (error) {
            setSp(false);
            if (error.response) {
                Swal.fire('Error', `${error.response.data.message || 'Hubo un problema al realizar la búsqueda'}`, 'error');
            } else {
                Swal.fire('Error', 'Hubo un problema al realizar la búsqueda', 'error');
            }
        } finally {
            setSp(false);
        }
    };

    useEffect(() => {
        const isNumeric = /^\d{11}$/.test(searchTerm);
        if (isNumeric && data.bodega) {
            handleSearch();
        }
    }, [searchTerm, data.bodega]);

    const columns = Array.from({ length: columnsPerRow }, (_, index) => ({
        cell: (row) => row[index]?.ubicacion__cod_ubicacion || '',
        filterable: true,
        width: '100px',
    }));


    return (
        <div className="px-4">
            {sp ? <Spinner /> : ''} {/* LOADER */}
            {listBodegas && listBodegas.length === 0 ? (
                <div className="ticket-empty-modal pt-4">
                    <div><i className="fas fa-box-open fs-20"></i></div>
                    <div className="text-empty">En este momento no hay nada para mostrar</div>
                </div>
            ) : (
                <div>
                    {listBodegas && listBodegas.length > 1 ? (
                        <Select
                            className="w-100 mb-0"
                            placeholder="Selecciona una bodega"
                            optionFilterProp="children"
                            onChange={handleChangeSelect}
                        >
                            {listBodegas.map((lb, indexlb) => (
                                <Option name={1} key={indexlb + lb.codigo} value={lb.id}>
                                    {lb.nombre}
                                </Option>
                            ))}
                        </Select>
                    ) : (
                        <div className="text-capitalize">
                            <span className="fw-600">Bodega:</span> {listBodegas[0]?.nombre.toLowerCase()}
                        </div>
                    )}

                    {data.bodega !== '' && (
                        <section className='button-container text-center mt-4 mb-4 flex-wrap'>                    
                            <Upload 
                                accept=".xls, .xlsx"
                                maxCount={1}
                                onChange={onChangeImg}
                                beforeUpload={beforeUpload} 
                                className='fit-content upload-masivas'
                            >
                                <div className='cursor-pointer btn-general-red py-1'>Buscar</div>    
                            </Upload>
                            <div className='cursor-pointer btn-general-gray height-fit' onClick={handleDownload}>Plantilla</div>  
                        </section>
                    )}

                </div>
            )}

        </div>
    );
    
};

export default Masivas;

