import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ConsultarReferencia from '../components/tiendas/consultar_referencia/IndexPage.js';

const ExternalRouters = () => (
    <Switch>
        <Route exact path="/consultar-referencias" component={ConsultarReferencia} />
    </Switch>
);

export { ExternalRouters };
