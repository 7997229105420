import React, { useState, useEffect } from 'react';
import Call from '../../../../config/Call';
import Spinner from '../../../base/spiner';
import { Select } from 'antd';
import Swal from 'sweetalert2';
import DataTableComp from '../../../produccion/autoriaLotes/dataTableComp';

const { Option } = Select;

const History = (props) => {
    const [sp, setSp] = useState(false);
    const [listBodegas, setListBodegas] = useState([]);
    const [data, setData] = useState({ bodega: '', type: '' });
    const [searchTerm, setSearchTerm] = useState('');
    const [locationsList, setLocationsList] = useState([]);
    const [columnsPerRow, setColumnsPerRow] = useState([]); 

    useEffect(() => {
        setListBodegas(props.listBodegas);
    }, [props.listBodegas]);

    useEffect(() => {
        const handleResize = () => {

            if (window.innerWidth >= 1700) {
                setColumnsPerRow(7);
            } else if (window.innerWidth >= 1400) {
                setColumnsPerRow(5); 
            } else {
                setColumnsPerRow(3);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    const groupLocations = (locations, groupSize) => {
        let grouped = [];
        for (let i = 0; i < locations.length; i += groupSize) {
            grouped.push(locations.slice(i, i + groupSize));
        }
        return grouped;
    };

    const handleChangeSelect = (option) => {
        setData((prevData) => ({ ...prevData, bodega: option }));
        setLocationsList([]);
    };

    const handleSearch = async () => {
        try {

            if (!data.bodega || !searchTerm) {
                Swal.fire('Error', 'Por favor selecciona una bodega e ingresa un término de búsqueda', 'warning');
                return;
            }

            setSp(true);

            const res = await Call('GET', `/api/bodegas/historial_ubicaciones/${data.bodega}?search=${searchTerm}`, true,null,false);

            if (res.status === 400) {
                Swal.fire('Error', res.data.message || 'Hubo un problema con la búsqueda', 'error');
            } else if (res.status === 200) {
                setLocationsList(groupLocations(res.data.data, columnsPerRow));
            } else {
                Swal.fire('Error', 'Hubo un problema con la solicitud', 'error');
            }
        } catch (error) {
            setSp(false);
            if (error.response) {
                Swal.fire('Error', `${error.response.data.message || 'Hubo un problema al realizar la búsqueda'}`, 'error');
            } else {
                Swal.fire('Error', 'Hubo un problema al realizar la búsqueda', 'error');
            }
        } finally {
            setSp(false);
        }
    };

    useEffect(() => {
        const isNumeric = /^\d{11}$/.test(searchTerm);
        if (isNumeric && data.bodega) {
            handleSearch();
        }
    }, [searchTerm, data.bodega]);

    const columns = Array.from({ length: columnsPerRow }, (_, index) => ({
        cell: (row) => row[index]?.ubicacion__cod_ubicacion || '',
        filterable: true,
        width: '100px',
    }));

    const customStyles2 = {
        headCells: {
            style: {
                fontSize: '14px',
                paddingLeft: '16px',
                paddingRight: '16px',
                color: '#678098',
            },
        },
        cells: {
            style: {
                fontSize: '14px',
            },
        },
    };

    return (
        <div className="px-4">
            {sp ? <Spinner /> : ''} {/* LOADER */}
    
            {listBodegas && listBodegas.length === 0 ? (
                <div className="ticket-empty-modal pt-4">
                    <div><i className="fas fa-box-open fs-20"></i></div>
                    <div className="text-empty">En este momento no hay nada para mostrar</div>
                </div>
            ) : (
                <div>
                    {listBodegas && listBodegas.length > 1 ? (
                        <Select
                            className="w-100 mb-0"
                            placeholder="Selecciona una bodega"
                            optionFilterProp="children"
                            onChange={handleChangeSelect}
                        >
                            {listBodegas.map((lb, indexlb) => (
                                <Option name={1} key={indexlb + lb.codigo} value={lb.id}>
                                    {lb.nombre}
                                </Option>
                            ))}
                        </Select>
                    ) : (
                        <div className="text-capitalize">
                            <span className="fw-600">Bodega:</span> {listBodegas[0]?.nombre.toLowerCase()}
                        </div>
                    )}
    
                    {data.bodega && (
                        <div className="mt-3">
                             <input
                                type="text"
                                className="form-control form-control-sm input-buscar-listform"
                                placeholder="&#xF002;  Buscar"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter' && data.bodega) {
                                        handleSearch();
                                    }
                                }}
                            />
                        </div>
                    )}
                </div>
            )}

            {locationsList.length === 0 && data.bodega && (
                <div className="ticket-empty-modal pt-4">
                    <div><i className="fas fa-box-open fs-20"></i></div>
                    <div className="text-empty">No hay ubicaciones para mostrar</div>
                </div>
            )}
    
            {locationsList.length > 0 && (
                <div>
                    <div className="mt-4" style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '25px', color: '#678098' }}>
                        Ubicaciones
                    </div>

                    <style>{`header.sc-dIouRR { display: none;}`}</style>

                    <DataTableComp
                        data={locationsList}
                        columns={columns}
                        customStyles={customStyles2}
                        paginationPerPage={locationsList.length}
                    />
                </div>
            )}

        </div>
    );
    
};

export default History;

