import React, { useState, useEffect } from 'react';
import { Modal, Empty } from 'antd';

const ImageModal = ({ visible, onClose, imageUrl }) => {
    const [imageError, setImageError] = useState(false);

    useEffect(() => {
        setImageError(false);
    }, [imageUrl]);

    const handleImageError = () => {
        setImageError(true);
    };

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            footer={null}
            centered
            title="Imagen del Producto"
        >
            <div style={{ textAlign: 'center' }}>
                {imageError || !imageUrl ? (
                    <Empty description="No se encontró una imagen" />
                ) : (
                    <img
                        key={imageUrl}
                        src={imageUrl}
                        alt="Imagen del Producto"
                        style={{ width: '100%', maxWidth: '500px', height: 'auto' }}
                        onError={handleImageError}
                    />
                )}
            </div>
        </Modal>
    );
};

export default ImageModal;
